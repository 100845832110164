import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import { Manitrocars } from "../../../Data/RectinaGlaucoma";
import CategorySelector from "../../category/CategorySelector";
import ImageSlider from "../../slider/ImageSlider";
import Popup from "../../common/Popup";
import { useProductFromSlug } from "../../../hooks/useIndexFromUrl";

const ManiTrocarsandInfusionCannulas = () => {
  // const [activeIndex, setActiveIndex] = useProductFromSlug(Manitrocars, 0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
    setProductData({
      category: "Retina & Glaucoma",
      subCategory: "ManiTrocarsandInfusionCannulas",
      product: item.model,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  // const activeCategory = Manitrocars[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | Mani Trocars & Infusion Cannulas"}
        centerHeading={"Mani Trocars & Infusion Cannulas"}
      />

      <section
        className="w-full  md:py-[6vw] pt-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {/* <CategorySelector
          categories={erg}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        /> */}

        {Manitrocars.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-[55%_45%] gap-y-40px-mvw md:gap-y-0 grid-cols-1 py-40px-mvw md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[2vw] max-h-[3.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-10px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-start  gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] flex-shrink-0 md:mt-[0.6vw] mt-10px-mvw rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center   ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full  md:pb-0 gap-x-16px-vw md:pt-[3vw] md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 mx-auto flex items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                  productData={{
                    category: "Retina & Glaucoma",
                    subCategory: "ManiTrocarsandInfusionCannulas",
                    product: item.model,
                  }}
                />
              </div>
            }
            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.29vw] pt-60px-mvw ">
                  <h1 className="font-raleway text-center py-40px-mvw md:py-60px-vw font-bold md:text-32px-vw md:pt-0  md:pb-0 text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}

                  <ImageSlider data={item.imggallery} />
                  {/* <div className=" hidden md:flex md:flex-row items-center flex-col gap-y-[4vw] md:gap-y-0 justify-center">
                      {item.imggallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] max-w-[50vw] md:max-h-[24.23vw] md:mr-[6.25vw] object-cover"
                        />
                      ))}
                    </div> */}

                  {/* Separator Line */}
                  {/* <div className="absolute left-0 md:w-full md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                  {/* Video and Image Navigation */}
                  {/* <h1 className="font-raleway text-center font-bold md:text-32px-vw md:pt-[10vw] pb-40px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>
                  <div className="md:pt-80px-vw pb-40px-mvw md:pb-0 flex flex-col items-start relative">
                    <div className="flex md:flex-row flex-col items-center md:gap-x-80px-vw">
                      {item.videoSrc.map((videoSrc, index) => (
                        <div key={index} className="mb-8">
                       
                          <img
                            src={videoSrc.src}
                            alt={`video-${index}`} 
                            className="md:max-w-[42.24vw] block"
                          />
                          <div className="md:mt-15px-vw mt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                            {videoSrc.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              ))}
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default ManiTrocarsandInfusionCannulas;
