import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast"; // Import Toaster
import CategoryTopSection from "../../components/category/CategoryTopSection";
import SectionHeading from "../../components/common/SectionHeading";
import Button from "../../components/common/Button";
import Aos from "aos";
import Joi from "joi";
import helper from "../../helper/helper";
import config from "../../config/config";

const Contact = () => {
  const contactInfo = [
    {
      id: 1,
      icon: "/images/contact/mail.svg",
      title: "Email:",
      text: "info@myhealthskape.com",
      link: "mailto:info@myhealthskape.com", // Email link
    },
    {
      id: 2,
      icon: "/images/contact/address.svg",
      title: "Address:",
      text: "My Healthskape Medicals Pvt Ltd <br/> 4/425A, Sharma Industrial Estate, Walbhat Road, <br/> Goregaon (East), Mumbai - 400 063 India.",
      link: "https://www.google.com/maps?q=My+Healthskape+Medicals+Pvt+Ltd", // Google Maps link
    },
    {
      id: 3,
      icon: "/images/contact/phone.svg",
      title: "Contact number:",
      text: "+91-22-26862626 / 26862828 / 26862929",
      link: "tel:+912226862626", // Telephone link
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const baseUrl = config.api_url;

  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().messages({
      "string.empty": "Name is required.",
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Email is required.",
        "string.email": "Please enter a valid email address.",
      }),
    phone: Joi.string()
      .pattern(/^[0-9]{10}$/)
      .required()
      .messages({
        "string.empty": "Phone number is required.",
        "string.pattern.base": "Phone number must be 10 digits",
        "any.required": "Phone number is required",
      }),
    message: Joi.string().allow(""),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validationResult = schema.extract(name).validate(value);
    if (validationResult.error) {
      setErrors({
        ...errors,
        [name]: validationResult.error.details[0].message,
      });
    } else {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = schema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const newErrors = {};
      validationResult.error.details.forEach((detail) => {
        newErrors[detail.path[0]] = detail.message;
      });
      setErrors(newErrors);
      setIsSubmitted(false);
      const firstErrorField = validationResult.error.details[0].path[0];
      if (firstErrorField) {
        helper.scroll(`${firstErrorField}-field`);
      }
      return;
    }

    const response = await helper.post(`${baseUrl}/contact-leads`, formData);

    if (response?.data) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setErrors({});
      helper.scroll(`success-field`);
    }
    setIsSubmitted(true);
    // Resetting form and errors on successful submission

    // Show success notification
    // Show success toast at the bottom
    // toast.success("Form submitted successfully!", {
    //   position: "bottom-center",
    // });
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | Contact Us"}
        centerHeading={"Contact Us"}
      />

      <div
        className="grid md:grid-cols-2 grid-cols-1 md:px-[7.29vw] px-16px-mvw py-60px-mvw md:py-[6.25vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {/* left side */}
        <div className="justify-self-start block md:hidden pb-20px-mvw md:mb-60px-vw">
          <SectionHeading title={"GET IN TOUCH"} />
        </div>
        <div className="flex flex-col items-start gap-y-20px-mvw md:gap-y-80px-vw justify-start">
          {contactInfo.map((item) => (
            <a
              key={item.id}
              href={item.link}
              className="flex items-start gap-x-16px-mvw md:gap-x-20px-vw"
            >
              <div className="w-40px-mvw md:w-60px-vw flex-shrink-0">
                <img
                  src={item.icon}
                  alt="icon"
                  className="w-full h-full object-contain"
                />
              </div>
              <div
                className={`flex flex-col text-[#2B2A29] font-poppins items-start justify-start ${
                  item.title === "Address" ? "md:w-[60%]" : ""
                }`}
              >
                <div className="text-18px-mvw md:text-24px-vw font-semibold">
                  {item.title}
                </div>
                <div
                  className="text-16px-mvw md:text-22px-vw font-regular w-full break-words"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                ></div>
              </div>
            </a>
          ))}
          <div className="w-full hidden md:block  h-auto mt-10px-mvw md:mt-20px-vw">
            <a
              href="https://www.google.com/maps?q=My+Healthskape+Medicals+Pvt+Ltd"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src="/images/contact/map.png"
                alt="map"
                className="w-full h-auto object-cover"
              />
            </a>
          </div>
          <div className="w-full block md:hidden  h-auto mt-20px-mvw md:mt-20px-vw">
            <a
              href="https://www.google.com/maps?q=My+Healthskape+Medicals+Pvt+Ltd"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <img
                src="/images/contact/mobilemap.png"
                alt="map"
                className="w-full h-auto object-cover"
              />
            </a>
          </div>
        </div>

        {/* right side */}
        <div className="w-full md:pl-[6.46vw] pt-60px-mvw md:pt-0">
          <div className="justify-self-start hidden md:block md:mb-60px-vw">
            <SectionHeading title={"GET IN TOUCH"} />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-30px-mvw md:gap-y-60px-vw text-[#2B2A29] items-start justify-start">
              <div id="name-field" className="w-full ">
                <label
                  htmlFor="name"
                  className="font-poppins text-[#2B2A29] font-medium md:text-28px-vw text-18px-mvw"
                >
                  Name*
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw  md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your name"
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

              <div id="email-field" className="w-full">
                <label
                  htmlFor="email"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Email*
                </label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw  md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your email Id"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              <div id="phone-field" className="w-full">
                <label
                  htmlFor="phone"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Phone Number*
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw  md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your phone number"
                />
                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              </div>

              <div id="message-field" className="w-full">
                <label
                  htmlFor="message"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Message
                </label>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw  md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your message"
                />
              </div>

              <div className="w-full mt-20px-mvw md:mt-0 text-center md:text-left ">
                <Button title={"Submit"} />
              </div>

              <div id="success-field">
                {isSubmitted && (
                  <p className="font-poppins text-green-700 italic mt-2 text-lg">
                    Thank you for your enquiry! We'll get back to you soon.
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
