import Fuse from "fuse.js";

export const data = [
  // Retina & Glaucoma
  // 1. OCT
  {
    name: "OCT",
    img: "/images/category/1.png",
    link: "/products/retina-glaucoma/oct",
    isSubcategory: true,
    products: [
      {
        name: "REVO FC 130",
        img: "/images/rectina/FC130-New.png",
        link: "/products/retina-glaucoma/oct/revo-fc-130",
      },
      {
        name: "REVO FC",
        img: "/images/rectina/Revo-FC.png",
        link: "/products/retina-glaucoma/oct/revo-fc",
      },
      {
        name: "REVO 60",
        img: "/images/rectina/revo-fc60.png",
        link: "/products/retina-glaucoma/oct/revo-60",
      },
    ],
  },
  {
    name: "Perimeters",
    img: "/images/category/2.png",
    link: "/products/retina-glaucoma/perimeters",
    isSubcategory: true,
    products: [
      {
        name: "PTS 2000",
        img: "/images/rectina/pts-2000.png",
        link: "/products/retina-glaucoma/perimeters/pts-2000",
      },
      {
        name: "PTS 925W",
        img: "/images/rectina/20.-PTS-925W-(1).png",
        link: "/products/retina-glaucoma/perimeters/pts-925w",
      },
    ],
  },

  {
    name: "Tonometer",
    img: "/images/category/3.png",
    link: "/products/retina-glaucoma/tonometer",
    isSubcategory: true,
    products: [
      {
        name: "A900 | F900 | Z800",
        img: "/images/rectina/tonoimg.png",
        link: "/products/retina-glaucoma/tonometer/a900-f900-z800",
      },
      // {
      //   name: "FT-1000",
      //   img: "/images/rectina/Fttono.png",
      //   link: "/products/retina-glaucoma/tonometer/ft-1000",
      // },
    ],
  },
  {
    name: "Pachymeter",
    img: "/images/rectina/Pachette 4.png",
    link: "/products/retina-glaucoma/pachymeter",
    isSubcategory: true,
    category: "Retina & Glaucoma",
    products: [
      {
        name: "Pachette 4",
        img: "/images/rectina/Pachette%204.png",
        link: "/products/retina-glaucoma/pachymeter/pachette-4",
      },
      {
        name: "Pachmate 2",
        img: "/images/rectina/Pachmate-2.png",
        link: "/products/retina-glaucoma/pachymeter/pachmate-2",
      },
    ],
  },
  {
    name: "ERG",
    img: "/images/category/5.png",
    link: "/products/retina-glaucoma/erg",
    isSubcategory: true,
    products: [
      {
        name: "RETEVAL",
        img: "/images/rectina/RETeval-full-side-view_pupil.png",
        link: "/products/retina-glaucoma/erg/reteval",
      },
      // {
      //   name: "EP-1000 Multifocal",
      //   img: "/images/rectina/EP-1000Mf.png",
      //   link: "/products/retina-glaucoma/erg/ep-1000-multifocal",
      // },
    ],
  },
  {
    name: "B-Scan",
    img: "/images/category/6.png",
    link: "/products/retina-glaucoma/b-scan",
    isSubcategory: true,
    category: "Rectina & Glaucoma",
    products: [
      {
        name: "SCANMATE FLEX",
        img: "/images/rectina/Scanmate-FLEX.png",
        link: "/products/retina-glaucoma/scanmate-flex",
      },
      {
        name: "UD-800",
        img: "/images/rectina/UD-800_Side_0002.png",
        link: "/products/retina-glaucoma/ud-800",
      },
    ],
  },
  // Cornea & Refractive
  // 1. Topographer
  {
    name: "Topographer",
    img: "/images/cornea/cor-1.png",
    link: "/products/cornea-refractive/topographer",
    isSubcategory: true,
    products: [
      {
        name: "ANTARES + Corneal Topographer",
        img: "/images/cornea/ant1.png",
        link: "/products/cornea-refractive/topographer/antares-plus",
      },
      {
        name: "SIRIUS +",
        img: "/images/cornea/ant2.png",
        link: "/products/cornea-refractive/topographer/sirius-plus",
      },
      {
        name: "TMS-4N",
        img: "/images/cornea/ant3.png",
        link: "/products/cornea-refractive/topographer/tms-4n",
      },
    ],
  },
  // Cornea & Refractive
  // 2.Lasik Laser System
  {
    name: "Lasik Laser System",
    img: "/images/plp/cornea/2.png",
    link: "/products/cornea-refractive/lasik-laser-system",
    returnAsSingle: true,
    products: [
      {
        name: "Wavelight Refractive Suite EX 500 + FS 200",
        img: "/images/plp/cornea/2.png",
        link: "/products/cornea-refractive/lasik-laser-system",
      },
      {
        name: "Wavelight Topolyzer Vario",
        img: "/images/plp/cornea/2.png",
        link: "/products/cornea-refractive/lasik-laser-system",
      },
    ],
  },
  // 3.Microkeratome
  {
    name: "Microkeratome",
    img: "/images/cornea/mor1.png",
    link: "/products/cornea-refractive/microkeratome",
    products: [
      {
        name: "Moria Evo 3e console with DSAEK + ALTK set",
        img: "/images/cornea/kerimg1.png",
        link: "/products/cornea-refractive/keratoplasty",
      },
    ],
  },
  // 4.Pachymeter
  {
    name: "Pachymeter",
    img: "/images/cornea/cor-4.png",
    link: "/products/cornea-refractive/pachymeter",
    isSubcategory: true,
    category: "Cornea & Refractive",
    products: [
      {
        name: "PACHETTE 4",
        img: "/images/rectina/Pachette%204.png",
        link: "/products/cornea-refractive/pachymeter/pachette-4",
      },
      {
        name: "PACHMATE 2",
        img: "/images/rectina/Pachmate-2.png",
        link: "/products/cornea-refractive/pachymeter/pachmate-2",
      },
    ],
  },
  {
    name: "AS-OCT",
    img: "/images/cornea/cor-5.png",
    link: "/products/cornea-refractive/as-oct",
    isSubcategory: true,
    products: [
      {
        name: "MS-39",
        img: "/images/cornea/asimg.png",
        link: "/products/cornea-refractive/as-oct/ms-39",
      },
      {
        name: "CASIA2",
        img: "/images/cornea/asimg1.png",
        link: "/products/cornea-refractive/as-oct/casia2",
      },
    ],
  },
  {
    name: "Keratoplasty",
    img: "/images/cornea/cor-6.png",
    link: "/products/cornea-refractive/keratoplasty",
    isSubcategory: true,
    products: [
      {
        name: "VACUUM TREPHINE & PUNCH",
        img: "/images/cornea/kerimg.png",
        link: "/products/cornea-refractive/keratoplasty/vacuum-trephinepunch",
      },
      {
        name: "DSAEK + ALTK SYSTEM",
        img: "/images/cornea/kerimg1.png",
        link: "/products/cornea-refractive/keratoplasty/dsaek-altk-system",
      },
    ],
  },
  {
    name: "Dry Eye",
    img: "/images/cornea/eye.png",
    link: "/products/cornea-refractive/dry-eye",
    isSubcategory: true,
    products: [
      {
        name: "IDRA",
        img: "/images/cornea/dry.png",
        link: "/products/cornea-refractive/dry-eye/idra",
      },
      {
        name: "ACTIVA",
        img: "/images/cornea/active.png",
        link: "/products/cornea-refractive/dry-eye",
      },
      {
        name: "TEARCHECK",
        img: "/images/cornea/tear.png",
        link: "/products/cornea-refractive/dry-eye/tearcheck",
      },
      {
        name: "E-EYE",
        img: "/images/cornea/eye.png",
        link: "/products/cornea-refractive/dry-eye",
      },
    ],
  },
  {
    name: "Specular Microscope",
    img: "/images/cornea/cor-8.png",
    link: "/products/cornea-refractive/specular-microscope",
    isSubcategory: true,
    products: [
      {
        name: "EM-4000",
        img: "/images/cornea/specular.png",
        link: "/products/cornea-refractive/specular-microscope",
      },
    ],
  },
  {
    name: "UBM",
    img: "/images/cornea/cor-9.png",
    link: "/products/cornea-refractive/ubm",
    isSubcategory: true,
    category: "Cornea & Refractive",
    products: [
      {
        name: "SCANMATE FLEX",
        img: "/images/rectina/Scanmate-FLEX.png",
        link: "/products/cornea-refractive/scanmate-flex",
      },
      {
        name: "UD-800",
        img: "/images/rectina/UD-800_Side_0002.png",
        link: "/products/cornea-refractive/ud-800",
      },
    ],
  },
  {
    name: "Ultrasound A-Scan",
    img: "/images/cataract/cat-6.png",
    link: "/products/cataract/ultrasound-a-scan",
    isSubcategory: true,
    products: [
      {
        name: "AL-4000",
        img: "/images/cataract/al-4000@2x.png",
        link: "/products/cataract/ultrasound-a-scan/al-4000",
      },
      {
        name: "Scanmate A DGH 600",
        img: "/images/cataract/scanmate-a@2x.png",
        link: "/products/cataract/ultrasound-a-scan/scanmate-a",
      },
    ],
  },
  {
    name: "Optical Biometer",
    img: "/images/cataract/cat-5.png",
    link: "/products/cataract/optical-biometer",
    isSubcategory: true,
    products: [],
  },
  {
    name: "Wavefront Aberrometer",
    img: "/images/cataract/cat-4.png",
    link: "/products/cataract/wavefront-aberrometer",
    isSubcategory: true,
    products: [
      {
        name: "Wavefront Aberrometer",
        img: "/images/cataract/osiris-t-1@2x.png",
        link: "/products/cataract/wavefront-aberrometer",
      },
    ],
  },
  {
    name: "Selective Laser Capsulotomy",
    img: "/images/cataract/pavel.png",
    link: "/products/cataract/selective-laser-capsulotomy",
    isSubcategory: true,
    products: [
      {
        name: "CAPSULaser",
        img: "/images/cataract/pavel.png",
        link: "/products/cataract/selective-laser-capsulotomy",
      },
    ],
  },
  {
    name: "Surgical Blades",
    img: "/images/cataract/cat-2.png",
    link: "/products/cataract/surgical-blades",
    isSubcategory: true,
    products: [
      {
        name: "Mani Ophthalmic Knife",
        img: "/images/cataract/surgical-blades@2x.png",
        link: "/products/cataract/surgical-blades",
      },
    ],
  },
  {
    name: "OT Table & Chair",
    img: "/images/cataract/cat-1.png",
    link: "/products/cataract/OTTable-Chair",
    isSubcategory: true,
    products: [
      {
        name: "500 XLE",
        img: "/images/cataract/500-XLE.png",
        link: "/products/cataract/OTTable-Chair/500-xle",
      },
      {
        name: "SurgiLine",
        img: "/images/cataract/SurgiLine.png",
        link: "/products/cataract/OTTable-Chair/surgiline",
      },
    ],
  },
  {
    name: "Slit-Lamp",
    img: "/images/primary/SL9800-ELITE.png",
    link: "/products/primary-diagonostics/slit-lamp",
    isSubcategory: true,
    products: [
      {
        name: "SL9900/ SL9800",
        img: "/images/primary/SL9800.png",
        link: "/products/primary-diagonostics/slit-lamp/sl9900-sl9800",
      },
      {
        name: "SL9800 ELITE",
        img: "/images/primary/SL9800-ELITE.png",
        link: "/products/primary-diagonostics/slit-lamp/sl9800-elite",
      },
    ],
  },
  {
    name: "Lensmeter",
    img: "/images/primary/pri-2.png",
    link: "/products/primary-diagonostics/lensmeter",
    isSubcategory: true,
    products: [
      {
        name: "TL-7000",
        img: "/images/primary/TL-7000.png",
        link: "/products/primary-diagonostics/lensmeter/tl-7000",
      },
      {
        name: "TL-6000",
        img: "/images/primary/TL-6000.png",
        link: "/products/primary-diagonostics/lensmeter/tl-6000",
      },
    ],
  },
  {
    name: "Steam Sterilizers",
    img: "/images/sterilization/str-1.png",
    link: "/products/sterilization-indicators/steam-sterilizers",
    isSubcategory: true,
    products: [
      {
        name: "HS-60SL",
        img: "/images/sterilization/HS-60SL.png",
        link: "/products/sterilization-indicators/steam-sterilizers/hs-60sl",
      },
      {
        name: "HS-2522BL",
        img: "/images/sterilization/HS-2522BL.png",
        link: "/products/sterilization-indicators/steam-sterilizers/hs-2522bl",
      },
      {
        name: "HS-2522SD",
        img: "/images/sterilization/HS-2522SD.png",
        link: "/products/sterilization-indicators/steam-sterilizers/hs-2522sd",
      },
      {
        name: "HS-1607SD",
        img: "/images/sterilization/HS-1607SD.png",
        link: "/products/sterilization-indicators/steam-sterilizers/hs-1607sd",
      },
      {
        name: "ENBIO S",
        img: "/images/sterilization/ENBIO_S.png",
        link: "/products/sterilization-indicators/steam-sterilizers/enbio-s",
      },
      {
        name: "ENBIO PRO",
        img: "/images/sterilization/ENBIO_PRO.png",
        link: "/products/sterilization-indicators/steam-sterilizers/enbio-pro",
      },
    ],
  },
  {
    name: "Indicator",
    img: "/images/sterilization/image002.png",
    link: "/products/sterilization-indicators/indicators",
    isSubcategory: true,
    products: [
      {
        name: "VAPOR LINE Steam Sterilization Indicator",
        img: "/images/sterilization/image002.png",
        link: "/products/sterilization-indicators/indicators",
      },
    ],
  },
  {
    name: "Packaging Material",
    img: "/images/sterilization/str-3.png",
    link: "/products/sterilization-indicators/packaging-material",
    isSubcategory: true,
    products: [
      {
        name: "Steriking Pouches & Roll",
        img: "/images/sterilization/img_006.png",
        link: "/products/sterilization-indicators/packaging-material/steriking-pouchesroll",
      },
      {
        name: "Rotosealer",
        img: "/images/sterilization/img_007.png",
        link: "/products/sterilization-indicators/packaging-material/rotosealer",
      },
    ],
  },

  // // Retina & Glaucoma
  // // 2. Perimeters

  // // Retina & Glaucoma
  // // 3. Tonometer

  // // Retina & Glaucoma
  // // 4. Pachymeter

  // // Retina & Glaucoma
  // // 5. ERG

  // // Retina & Glaucoma
  // // 6. B-Scan

  // // Retina & Glaucoma
  // // 7.Fundus Camera
  // {
  //   name: "Fundus Camera",
  //   img: "/images/category/7.png",
  //   link: "/products/retina-glaucoma/fundus-camera",
  // },

  // // Cornea & Refractive
  // // 5. AS-OCT
  // {
  //   name: "AS-OCT",
  //   img: "/images/cornea/cor-5.png",
  //   link: "/products/cornea-refractive/as-oct",
  // },
  // {
  //   name: "MS-39",
  //   img: "/images/cornea/asimg.png",
  //   link: "/products/cornea-refractive/as-oct",
  // },
  // {
  //   name: "CASIA2",
  //   img: "/images/cornea/asimg1.png",
  //   link: "/products/cornea-refractive/as-oct",
  // },

  // // Cornea & Refractive
  // // 6. Keratoplasty
  // {
  //   name: "Keratoplasty",
  //   img: "/images/cornea/cor-6.png",
  //   link: "/products/cornea-refractive/keratoplasty",
  // },
  // {
  //   name: "The adjustable vacuum Trephine & Punch for PK & DALK procedures",
  //   img: "/images/cornea/kerimg.png",
  //   link: "/products/cornea-refractive/keratoplasty",
  // },

  // // Cornea & Refractive
  // // 7. Dry Eye
  // {
  //   name: "Dry Eye",
  //   img: "/images/cornea/eye.png",
  //   link: "/products/cornea-refractive/dry-eye",
  // },
  // {
  //   name: "IDRA",
  //   img: "/images/cornea/dry.png",
  //   link: "/products/cornea-refractive/dry-eye",
  // },
  // {
  //   name: "ACTIVA",
  //   img: "/images/cornea/active.png",
  //   link: "/products/cornea-refractive/dry-eye",
  // },
  // {
  //   name: "TEARCHECK",
  //   img: "/images/cornea/tear.png",
  //   link: "/products/cornea-refractive/dry-eye",
  // },
  // {
  //   name: "E-EYE",
  //   img: "/images/cornea/eye.png",
  //   link: "/products/cornea-refractive/dry-eye",
  // },

  // // Cornea & Refractive
  // //  8. Specular Microscope
  // {
  //   name: "Specular Microscope",
  //   img: "/images/cornea/cor-8.png",
  //   link: "/products/cornea-refractive/specular-microscope",
  // },

  // // Cornea & Refractive
  // //  9. UBM
  // {
  //   name: "UBM",
  //   img: "/images/cornea/cor-9.png",
  //   link: "/products/cornea-refractive/ubm",
  // },
  // {
  //   name: "SCANMATE FLEX",
  //   img: "/images/rectina/Scanmate-FLEX.png",
  //   link: "/products/cornea-refractive/scanmate-flex",
  // },
  // {
  //   name: "UD-800",
  //   img: "/images/rectina/UD-800_Side_0002.png",
  //   link: "/products/cornea-refractive/ud-800",
  // },

  // // Cataract
  // // 1.Ultrasound A-Scan
  // {
  //   name: "Ultrasound A-Scan",
  //   img: "/images/cataract/cat-6.png",
  //   link: "/products/cataract/ultrasound-a-scan",
  // },
  // {
  //   name: "AL-4000",
  //   img: "/images/cataract/al-4000@2x.png",
  //   link: "/products/cataract/ultrasound-a-scan",
  // },
  // {
  //   name: "Scanmate A DGH 600",
  //   img: "/images/cataract/scanmate-a@2x.png",
  //   link: "/products/cataract/ultrasound-a-scan",
  // },

  // // Cataract
  // // 2.Optical Biometer
  // {
  //   name: "Optical Biometer",
  //   img: "/images/cataract/cat-5.png",
  //   link: "/products/cataract/optical-biometer",
  // },

  // // Cataract
  // // 3.Wavefront Aberrometer
  // {
  //   name: "Wavefront Aberrometer",
  //   img: "/images/cataract/cat-4.png",
  //   link: "/products/cataract/wavefront-aberrometer",
  // },

  // // Cataract
  // // 4.Selective Laser Capsulotomy
  // {
  //   name: "Selective Laser Capsulotomy",
  //   img: "/images/cataract/pavel.png",
  //   link: "/products/cataract/selective-laser-capsulotomy",
  // },

  // // Cataract
  // // 5.Surgical Blades
  // {
  //   name: "Surgical Blades",
  //   img: "/images/cataract/cat-2.png",
  //   link: "/products/cataract/surgical-blades",
  // },

  // // Cataract
  // // 6.OT Table & Chair
  // {
  //   name: "OT Table & Chair",
  //   img: "/images/cataract/cat-1.png",
  //   link: "/products/cataract/OTTable-Chair",
  // },
  // {
  //   name: "500 XLE",
  //   img: "/images/cataract/500-XLE.png",
  //   link: "/products/cataract/OTTable-Chair",
  // },
  // {
  //   name: "SurgiLine",
  //   img: "/images/cataract/SurgiLine.png",
  //   link: "/products/cataract/OTTable-Chair",
  // },

  // // Primary Diagnostics
  // // 1.Slit-Lamp
  // {
  //   name: "Slit-Lamp",
  //   img: "/images/primary/SL9800-ELITE.png",
  //   link: "/products/primary-diagonostics/slit-lamp",
  // },
  // {
  //   name: "SL9900/ SL9800",
  //   img: "/images/primary/SL9800.png",
  //   link: "/products/primary-diagonostics/slit-lamp",
  // },
  // {
  //   name: "SL9800 ELITE",
  //   img: "/images/primary/SL9800-ELITE.png",
  //   link: "/products/primary-diagonostics/slit-lamp",
  // },

  // // Primary Diagnostics
  // // 2.Lensmeter
  // {
  //   name: "Lensmeter",
  //   img: "/images/primary/pri-2.png",
  //   link: "/products/primary-diagonostics/lensmeter",
  // },
  // {
  //   name: "TL-7000",
  //   img: "/images/primary/TL-7000.png",
  //   link: "/products/primary-diagonostics/lensmeter",
  // },
  // {
  //   name: "TL-6000",
  //   img: "/images/primary/TL-6000.png",
  //   link: "/products/primary-diagonostics/lensmeter",
  // },

  // // STERILIZATION & INDICATORS PRODUCTS
  // //  1.Steam Sterilizers
  // {
  //   name: "Steam Sterilizers",
  //   img: "/images/sterilization/str-1.png",
  //   link: "/products/sterilization-indicators/steam-sterilizers",
  // },
  // {
  //   name: "HS-60SL",
  //   img: "/images/sterilization/HS-60SL.png",
  //   link: "/products/sterilization-indicators/steam-sterilizers",
  // },
  // {
  //   name: "HS-2522BL",
  //   img: "/images/sterilization/HS-2522BL.png",
  //   link: "/products/sterilization-indicators/steam-sterilizers",
  // },
  // {
  //   name: "HS-2522SD",
  //   img: "/images/sterilization/HS-2522SD.png",
  //   link: "/products/sterilization-indicators/steam-sterilizers",
  // },
  // {
  //   name: "HS-1607SD",
  //   img: "/images/sterilization/HS-1607SD.png",
  //   link: "/products/sterilization-indicators/steam-sterilizers",
  // },

  // // STERILIZATION & INDICATORS PRODUCTS
  // //  2. Indicator
  // {
  //   name: "Indicator",
  //   img: "/images/sterilization/image002.png",
  //   link: "/products/sterilization-indicators/indicators",
  // },

  // // STERILIZATION & INDICATORS PRODUCTS
  // // 3.Packaging Material
  // {
  //   name: "Packaging Material",
  //   img: "/images/sterilization/str-3.png",
  //   link: "/products/sterilization-indicators/packaging-material",
  // },
  // {
  //   name: "Steriking Pouches & Roll",
  //   img: "/images/sterilization/img_006.png",
  //   link: "/products/sterilization-indicators/packaging-material",
  // },
  // {
  //   name: "Rotosealer",
  //   img: "/images/sterilization/img_007.png",
  //   link: "/products/sterilization-indicators/packaging-material",
  // },
];

const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  threshold: 0.2,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name"],
};
export const search = (query) => {
  if (!query) {
    return [];
  }

  const searchTerm = query.toLowerCase().trim();

  // Make the threshold stricter for more precise matching
  const fuseOptions = {
    threshold: 0.1, // Lower threshold means more precise matching
    keys: ["name"],
  };

  // Special handling for pachymeter searches - only for general "pach" searches
  const isPachymeterGeneralSearch =
    searchTerm === "pach" || searchTerm === "pachymeter";

  // Collect all products with their subcategory information
  const allProducts = [];
  data.forEach((subcategory) => {
    if (subcategory.products && subcategory.products.length > 0) {
      subcategory.products.forEach((product) => {
        allProducts.push({
          ...product,
          subcategory: subcategory.name,
          subcategoryId:
            data.findIndex((subcat) => subcat.name === subcategory.name) + 1,
          returnAsSingle: subcategory.returnAsSingle || false,
          category: subcategory.category || "",
        });
      });
    }
  });

  // For specific product searches like "pachette 4" or "pachmate 2"
  if (searchTerm.includes("pachette") || searchTerm.includes("pachmate")) {
    // For specific product searches, use more precise matching
    const exactProducts = allProducts.filter((product) =>
      product.name.toLowerCase().includes(searchTerm)
    );

    if (exactProducts.length > 0) {
      // If we have exact matches, process only those
      const exactResults = new Map();

      exactProducts.forEach((item) => {
        const subcategory = data.find(
          (subcat) => subcat.name === item.subcategory
        );
        const id = `${item.subcategoryId}-exact-${item.name}`;

        exactResults.set(id, {
          item: {
            id,
            name: item.name,
            img: item.img,
            link: item.link,
            logo: item.logo,
          },
          context: {
            category: item.category || "",
            subcategory: item.subcategory,
          },
        });
      });

      return Array.from(exactResults.values());
    }
  }

  // Search through subcategories
  const fuseSubcategories = new Fuse(data, fuseOptions);
  const subcategoryResults = fuseSubcategories.search(query);

  // Search through products
  const fuseProducts = new Fuse(allProducts, fuseOptions);
  const productResults = fuseProducts.search(query);

  // Combine results
  const combinedResults = new Map(); // Using Map to avoid duplicates by id

  // Process subcategory results
  if (subcategoryResults.length > 0) {
    subcategoryResults.forEach(({ item }) => {
      // Special case for Pachymeter subcategories
      if (isPachymeterGeneralSearch && item.name === "Pachymeter") {
        // For Pachymeter, we want to show all products from all Pachymeter subcategories
        // We'll handle this when we process all subcategories at the end
      }
      // Handle subcategories with returnAsSingle flag
      else if (item.returnAsSingle) {
        const id = `single-${
          data.findIndex((subcat) => subcat.name === item.name) + 1
        }`;
        combinedResults.set(id, {
          item: {
            id,
            name: item.name,
            img: item.img,
            link: item.link,
          },
          context: {
            category: item.category || "",
          },
        });
      }
      // Handle normal subcategories with products
      else if (item.products && item.products.length > 0) {
        item.products.forEach((product, index) => {
          const id = `${
            data.findIndex((subcat) => subcat.name === item.name) + 1
          }-${index + 1}`;
          combinedResults.set(id, {
            item: {
              id,
              name: product.name,
              img: product.img,
              link: product.link,
            },
            context: {
              category: item.category || "",
              subcategory: item.name,
            },
          });
        });
      }
    });
  }

  // Process product results
  if (productResults.length > 0) {
    productResults.forEach(({ item }) => {
      // Special case for Pachymeter products
      if (isPachymeterGeneralSearch && item.subcategory === "Pachymeter") {
        // We'll handle all Pachymeter products together at the end
      }
      // For products belonging to returnAsSingle subcategories, add the subcategory instead
      else if (item.returnAsSingle) {
        const subcategoryIndex = data.findIndex(
          (subcat) => subcat.name === item.subcategory
        );
        const subcategory = data[subcategoryIndex];
        const id = `single-${subcategoryIndex + 1}`;
        combinedResults.set(id, {
          item: {
            id,
            name: subcategory.name,
            img: subcategory.img,
            link: subcategory.link,
          },
          context: {
            category: subcategory.category || "",
          },
        });
      } else {
        // Normal product handling
        const subcategory = data.find(
          (subcat) => subcat.name === item.subcategory
        );
        const id = `${item.subcategoryId}-${
          subcategory.products.findIndex((p) => p.name === item.name) + 1
        }`;
        combinedResults.set(id, {
          item: {
            id,
            name: item.name,
            img: item.img,
            link: item.link,
            logo: item.logo,
          },
          context: {
            category: item.category || "",
            subcategory: item.subcategory,
          },
        });
      }
    });
  }

  // Special handling for Pachymeter general search - add all pachymeter products from all categories
  if (isPachymeterGeneralSearch) {
    // Find all Pachymeter subcategories
    const pachymeterSubcategories = data.filter(
      (subcat) => subcat.name === "Pachymeter"
    );

    // Add all products from all Pachymeter subcategories
    pachymeterSubcategories.forEach((subcategory, subcatIndex) => {
      if (subcategory.products && subcategory.products.length > 0) {
        subcategory.products.forEach((product, prodIndex) => {
          const id = `pach-${subcatIndex}-${prodIndex}`;
          combinedResults.set(id, {
            item: {
              id,
              name: product.name,
              img: product.img,
              link: product.link,
              logo: product.logo,
            },
            context: {
              category: subcategory.category || "",
              subcategory: subcategory.name,
            },
          });
        });
      }
    });
  }

  // Convert Map values to array and return
  return Array.from(combinedResults.values());
};
