import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useRef, useEffect, useState } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import TestimonialSlider from "./TestimonialSlider";
import Slider from "react-slick";
import Aos from "aos";
import { testimonials } from "../../Data/DummyData";

const Testimonial = () => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [sliderHeight, setSliderHeight] = useState(0);

  // const updateSliderHeight = () => {
  //   const activeSlide = document.querySelector(
  //     `.slick-slide[data-index='${activeSlideIndex}']`
  //   );
  //   if (activeSlide) {
  //     setSliderHeight(activeSlide.offsetHeight);
  //   }
  // };
  const updateSliderHeight = () => {
    setTimeout(() => {
      const activeSlide = document.querySelector(".slick-active"); // Selects the current visible slide
      if (activeSlide) {
        setSliderHeight(activeSlide.offsetHeight);
      }
    }, 300); // Delay slightly to allow rendering
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true, // Center the active slide
    centerPadding: "10%", // Partially show the next/previous slides
    slidesToScroll: 1,
    arrows: true, // Use custom buttons
    nextArrow: <BsArrowRight />,
    prevArrow: <BsArrowLeft />,
    beforeChange: (current, next) => {
      // No opacity changes here
      const allSlides = document.querySelectorAll(".slick-slide");
      allSlides.forEach((slide) => {
        slide.style.opacity = "1"; // Set full opacity for all slides
      });
    },
    afterChange: (current) => {
      setActiveSlideIndex(current); // Update the active slide index
      const allSlides = document.querySelectorAll(".slick-slide");
      allSlides.forEach((slide) => {
        slide.style.opacity = "1"; // Ensure full opacity for all slides
      });
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show one slide
          centerMode: false,
        },
      },
    ],
  };
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });

    // Run after a short delay to ensure slider is mounted
    const timer = setTimeout(() => {
      updateSliderHeight();
    }, 300);

    window.addEventListener("resize", updateSliderHeight);

    return () => {
      window.removeEventListener("resize", updateSliderHeight);
      clearTimeout(timer);
    };
  }, []); 

  

  useEffect(() => {
    const activeSlide = document.querySelector(
      `.slick-slide[data-index='${activeSlideIndex}']`
    );
    if (activeSlide) {
      setSliderHeight(activeSlide.offsetHeight); // Adjust height based on the active slide
    }
  }, [activeSlideIndex]);

  const handlePrev = () => sliderRef.current.slickPrev();
  const handleNext = () => sliderRef.current.slickNext();

  return (
    <section
      className="w-full relative md:pl-[7.29vw] px-4 py-80px-mvw   md:py-[6.11vw] grid md:grid-cols-[35%_65%] grid-cols-1"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex flex-col items-start  gap-y-30px-vw">
        <div>
          <SectionHeading
            title={"SPEAK TO OUR EXPERT"}
            customCss={"uppercase md:max-w-[80%]"}
          />
        </div>
        <div className="w-full md:max-w-[20.73vw] max-w-full">
          <ParaText
            paratext={
              "Experience advanced technology firsthand. Schedule an in-person demonstration with our expert to discover the benefits our products offer."
            }
          />
        </div>

        <div className="rounded-[4vw] border-[1px] border-[#2B2A29] md:flex items-center justify-center px-24px-vw py-18px-vw hidden  cursor-pointer mt-30px-vw">
          <div className="inline-flex text-center gap-x-10px-vw">
            <button
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </button>
            <button
              className="text-[#2B2A29] font-poppins md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className=" relative w-full">
        <div
          style={{
            height: `${sliderHeight}px`,
            minHeight:
              window.innerWidth >= 768 ? `${sliderHeight}px` : undefined,
          }}
          className="overflow-hidden transition-all duration-300 "
        >
          <Slider ref={sliderRef} {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <TestimonialSlider
                key={index}
                testimonial={testimonial.quote}
                desc={testimonial.desc}
                imgsrc={testimonial.imgsrc}
                title={testimonial.name}
                designation={testimonial.designation}
                location={testimonial.location}
              />
            ))}
          </Slider>
        </div>
        <button className="w-full md:hidden flex items-center mt-40px-mvw md:mt-0  justify-center">
          <div className="rounded-[8vw] flex items-center border-[0.5px] border-[#2B2A29] opacity-100 px-[24px] py-[12px] space-x-[17px]">
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </div>
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </div>
          </div>
        </button>
      </div>
    </section>
  );
};

export default Testimonial;
