import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import { UltrasoundAScanData } from "../../../Data/Cataract";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import CategorySelector from "../../category/CategorySelector";
import Popup from "../../common/Popup";
import { useProductFromSlug } from "../../../hooks/useIndexFromUrl";

const UltrasoundAScan = () => {
  const [activeIndex, setActiveIndex] = useProductFromSlug(
    UltrasoundAScanData,
    0
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
    setProductData({
      category: "Cataract",
      subCategory: "Ultrasound A-Scan",
      product: item.model,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const [playingIndex, setPlayingIndex] = useState(null); // Track the index of the playing video

  const handlePlay = (index) => {
    setPlayingIndex(index); // Set the index of the video that is playing
  };

  const activeCategory = UltrasoundAScanData[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);

    // Reset the playing video when changing categories
    setPlayingIndex(null);
  }, [activeIndex]);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Ultrasound A-Scan"}
        centerHeading={"Ultrasound A-Scan"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={UltrasoundAScanData}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-y-40px-mvw md:pt-40px-vw pt-24px-mvw md:gap-y-[2vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[2vw] max-h-[3.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80  text-16px-mvw  md:max-w-[70%]">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center">
                {item.img.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {item?.additionalInfo[0]?.data && (
              <>
                <div className="w-full  gap-x-16px-vw md:pt-[3vw] md:px-[14vw] py-60px-mvw  flex gap-y-16px-mvw md:gap-y-0 flex-col md:flex-row items-center justify-center md:py-60px-vw">
                  <DownloadCard
                    data={item.additionalInfo}
                    onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                    productData={{
                      category: "Cataract",
                      subCategory: "Ultrasound A-Scan",
                      product: item.model,
                    }}
                  />
                </div>
                {/* {item.video &&
                item.video.map((videoItem, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-col items-center justify-center md:pt-[6.25vw]"
                  >
                    <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                      IMAGE & VIDEO GALLERY
                    </h1>
                    <div className="md:mt-60px-vw flex flex-col items-start relative">
                    
                      {playingIndex !== index ? (
                        <>
                          <img
                            src={videoItem.videoSrc} 
                            alt="video thumbnail"
                            className="md:max-w-[42.24vw] block"
                          />
                          <div
                            className="absolute cursor-pointer inset-0 flex items-center justify-center"
                            onClick={() => handlePlay(index)} 
                          >
                            <img
                              src="/icon/playIcon.png"
                              alt="play icon"
                              className="md:w-73px-vw"
                            />
                          </div>
                        </>
                      ) : (
                      
                        <iframe
                          className="md:max-w-[80vw] block"
                          width="700" 
                          height="450"
                          src={`https://www.youtube.com/embed/${videoItem.videoUrl
                            .split("/")
                            .pop()}`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      )}

                      <div className="md:pt-20px-vw pt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                        {videoItem.videoTitle}
                      </div>
                    </div>
                  </div>
                ))} */}
              </>
            )}
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default UltrasoundAScan;
