export const oct = [
  {
    id: 1,
    model: "REVO FC 130",
    slug: "revo-fc-130",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO FC 130",
        name: "The REVO FC 130 combines the world’s fastest SD-OCT with a non-mydriatic color fundus camera. Blaze through exams with a scan speed of 130,000 A-scans per second. Featuring our all-new AccuTrack™ real-time hardware-based eye tracker. Operating it is as simple as the push of a button.",
        main_points: [
          "1,30,000 A-scan per second",
          "12.3 MP Fundus Camera",
          "Wide Retina and Anterior",
          "Glaucoma Tool Kit",
          "Wide and Dense OCT Angiography Scans",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK™",
              img: "/images/rectina/center_logo.svg", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts.",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img8.svg",
              desc: "Simplifying operation with the push of a button to auto-position, auto-align, auto-focus and auto-capture.",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.svg",
              desc: "An advanced artificial intelligence (AI) algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/greendott.svg",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.svg",
              desc: "The software-based motion correction (MC) compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary.",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.svg",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.svg",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans.",
            },
            {
              id: 8,
              title: "CONNECTIVITY",
              img: "/images/rectina/img7.svg",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Revo-FC130.pdf",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New1.png",
              "/images/rectina/oko_00313__Revo_FC130-scaled.png",
              "/images/rectina/REVO-FC-front-View.png",
              "/images/rectina/oko_00306__Revo_FC130-scaled.png",
              // "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/rectina/pics1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Angiography OCT",
                videoDesc:
                  "Angiography OCT provides an alternative to the traditional fluorescein method. The software allows clinicians to observe, track and compare changes in the microvasculature of the retina in both eyes.",
              },
              {
                videothumbnail: "/images/rectina/pics2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Anterior",
                videoDesc:
                  "The built-in anterior lens allows the user to perform imaging of the anterior segment without installing additional lenses or a forehead adapter.",
              },
              {
                videothumbnail: "/images/rectina/pics3.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Biometry OCT",
                videoDesc:
                  "B-OCT® is an excellent tool for any clinician who manages myopia control or performs cataract surgery.",
              },
              {
                videothumbnail: "/images/rectina/pics4.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Full Range",
                videoDesc:
                  "With scans presenting New Extended Depth software, based on our Full Range technology, provides scans of increased depth for reliable and convenient observation of challenging cases.",
              },
              {
                videothumbnail: "/images/rectina/pics5.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Glaucoma Tool Kit",
                videoDesc:
                  "Comprehensive glaucoma analytical tools for quantiﬁcation of the Nerve Fiber Layer and Ganglion Cell Layer, and Disc Damage Likelihood Scale.",
              },
              {
                videothumbnail: "/images/rectina/pics6.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging with the hep of a 12.3 MP Fundus Camera.",
              },
              {
                videothumbnail: "/images/rectina/pics7.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Progression Analysis",
                videoDesc:
                  "Quickly view a chronological set of exams for analysis of changes in morphology, quantified progression maps, and progression trends.",
              },
              {
                videothumbnail: "/images/rectina/pics8.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Structure + Function",
                videoDesc:
                  "Our S+F report allows clinicians to understand the relationship between structural glaucoma damage and the functional impact on the patient’s field of vision.",
              },
              {
                videothumbnail: "/images/rectina/pics9.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Topography",
                videoDesc:
                  "Topography OCT is a pioneering way to provide detailed corneal curvature maps. Anterior, Posterior surfaces and Corneal Thickness provide the True Net Curvature information.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "REVO FC ",
    slug: "revo-fc",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO FC ",
        name: "The REVO FC 130 combines the ultra fast SD-OCT with a non-mydriatic color fundus camera. Featuring our all-new AccuTrack™ real-time hardware-based eye tracker. Operating it is as simple as the push of a button.",
        main_points: [
          "80,000 A-scan per second",
          "12.3 MP Fundus Camera",
          "Wide Retina and Anterior Scans",
          "Glaucoma Tool Kit",
          "Dense OCT Angiography Scans",
        ],

        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK™",
              img: "/images/rectina/center_logo.svg", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts.",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img8.svg",
              desc: "Simplifying operation with the push of a button to auto-position, auto-align, auto-focus and auto-capture.",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.svg",
              desc: "An advanced artificial intelligence (A.I.) algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/greendott.svg",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.svg",
              desc: "The software-based motion correction (MC) compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary.",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.svg",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.svg",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans.",
            },
            {
              id: 8,
              title: "NETWORK INTEGRATION",
              img: "/images/rectina/img7.svg",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },

        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Revo_FC_( NEW ).pdf",
          },
        ],
        img: ["/images/rectina/Revo-FC.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/oko_00306__Revo_FC-scaled.png",
              "/images/rectina/oko_00313__Revo_FC-scaled.png",
              "/images/rectina/Revo-FC(1).png",
              "/images/rectina/REVO-FC-front-View.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/rectina/rev1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Angiography OCT",
                videoDesc:
                  "Angiography OCT provides an alternative to the traditional fluorescein method. The software allows clinicians to observe, track and compare changes in the microvasculature of the retina in both eyes.",
              },
              {
                videothumbnail: "/images/rectina/rev2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Anterior",
                videoDesc:
                  "The built-in anterior lens allows the user to perform imaging of the anterior segment without installing additional lenses or a forehead adapter.",
              },
              {
                videothumbnail: "/images/rectina/rev3.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Biometry OCT",
                videoDesc:
                  "B-OCT® is an excellent tool for any clinician who manages myopia control or performs cataract surgery.",
              },
              {
                videothumbnail: "/images/rectina/rev4.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Full Range",
                videoDesc:
                  "With scans presenting New Extended Depth software, based on our Full Range technology, provides scans of increased depth for reliable and convenient observation of challenging cases.",
              },
              {
                videothumbnail: "/images/rectina/rev5.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Glaucoma Tool Kit",
                videoDesc:
                  "Comprehensive glaucoma analytical tools for quantiﬁcation of the Nerve Fiber Layer and Ganglion Cell Layer, and Disc Damage Likelihood Scale.",
              },
              {
                videothumbnail: "/images/rectina/rev6.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging with the hep of a 12.3 MP Fundus Camera.",
              },
              {
                videothumbnail: "/images/rectina/rev7.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Progression Analysis",
                videoDesc:
                  "Quickly view a chronological set of exams for analysis of changes in morphology, quantified progression maps, and progression trends.",
              },
              {
                videothumbnail: "/images/rectina/rev8.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Structure + Function",
                videoDesc:
                  "Our S+F report allows clinicians to understand the relationship between structural glaucoma damage and the functional impact on the patient’s field of vision.",
              },
              {
                videothumbnail: "/images/rectina/rev9.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Topography",
                videoDesc:
                  "Topography OCT is a pioneering way to provide detailed corneal curvature maps. Anterior, Posterior surfaces and Corneal Thickness provide the True Net Curvature information.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "REVO 60",
    slug: "revo-60",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO 60",
        name: "Our supreme experience in Spectral Domain OCT allows us to provide the market REVO 60 with redesign optics. The updated REVO 60 meets all demands in daily routine practice.",
        main_points: [
          "60,000 A-scan per second",
          "Wide Retina and Anterior Scans",
          "Glaucoma tool kit",
          "Completely Automatic",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/img8.svg",
              desc: "Simplifying operation with the push of a button to auto-position, auto-align, auto-focus and auto-capture.",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/img3.svg",
              desc: "An advanced artificial intelligence (A.I.) algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/greendott.svg",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "MOTION CORRECTION",
              img: "/images/rectina/img4.svg",
              desc: "The software-based motion correction (MC) compensates for involuntary eye movements and blinks by capturing two scans and generating a motion corrected scan when necessary.",
            },
            {
              id: 6,
              title: "STRUCTURE + FUNCTION (S+F)",
              img: "/images/rectina/img5.svg",
              desc: "Comprehensive glaucoma solution that combines REVO OCT and PTS Visual Field results. S+F takes the diagnostic approach of the Hood report.",
            },
            {
              id: 7,
              title: "PROGRESSION ANALYSIS",
              img: "/images/rectina/img6.svg",
              desc: "Gather baselines and follow-ups to monitor and manage disease progression in posterior and anterior scans.",
            },
            {
              id: 8,
              title: "NETWORK INTEGRATION",
              img: "/images/rectina/img7.svg",
              desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Revo-60.pdf",
          },
        ],
        img: ["/images/rectina/revo-fc60.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/6__Revo_60-scaled-(1).png",
              "/images/rectina/7__Revo_60-scaled.png",
              "/images/rectina/REVO-60-front.png",
              "/images/rectina/revo-fc60(1).png",
            ],

            videoData: [
              {
                videothumbnail: "/images/rectina/revs1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Anterior",
                videoDesc:
                  "The built-in anterior lens allows the user to perform imaging of the anterior segment without installing additional lenses or a forehead adapter.",
              },
              {
                videothumbnail: "/images/rectina/revs2.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Biometry OCT",
                videoDesc:
                  "B-OCT® is an excellent tool for any clinician who manages myopia control or performs cataract surgery.",
              },
              {
                videothumbnail: "/images/rectina/revs3.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Full Range",
                videoDesc:
                  "With scans presenting New Extended Depth software, based on our Full Range technology, provides scans of increased depth for reliable and convenient observation of challenging cases.",
              },
              {
                videothumbnail: "/images/rectina/revs4.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Glaucoma Tool Kit",
                videoDesc:
                  "Comprehensive glaucoma analytical tools for quantiﬁcation of the Nerve Fiber Layer and Ganglion Cell Layer, and Disc Damage Likelihood Scale.",
              },
              {
                videothumbnail: "/images/rectina/revs5.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Progression Analysis",
                videoDesc:
                  "Quickly view a chronological set of exams for analysis of changes in morphology, quantified progression maps, and progression trends.",
              },
              {
                videothumbnail: "/images/rectina/revs6.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Structure + Function",
                videoDesc:
                  "Our S+F report allows clinicians to understand the relationship between structural glaucoma damage and the functional impact on the patient’s field of vision.",
              },
              {
                videothumbnail: "/images/rectina/revs7.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
                videoTitle: "Topography",
                videoDesc:
                  "Topography OCT is a pioneering way to provide detailed corneal curvature maps. Anterior, Posterior surfaces and Corneal Thickness provide the True Net Curvature information.",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const perimeter = [
  {
    id: 1,
    model: "PTS 2000",
    slug: "pts-2000",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "PTS 2000",
        name: "The PTS 2000 is our top-of-the-line projection perimeter with modern and intuitive software available at your fingertips",
        main_points: [
          "Static and Kinetic Testing ",
          "Goldman I to V",
          "ZETA™, ZETA™ FAST, and ZETA™ Faster",
          "DPA™ Defect Progression Analysis",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/pts2000left.png",
          key_feature: [
            {
              id: 1,
              title: "FAST THRESHOLDING",
              img: "/images/rectina/lg1.png", // using common image
              desc: "Using ZETA™ thresholding algorithms, test your patients in under 3 minutes.",
            },
            {
              id: 2,
              title: "EYE TRACKING",
              img: "/images/rectina/lg2.png",
              desc: "Monitor patient pupil movement during examination.",
            },
            {
              id: 3,
              title: "BLINK DETECTION",
              img: "/images/rectina/lg3.png",
              desc: "Our software detects when the eye is closed, and will not present stimuli to the patient.",
            },
            {
              id: 4,
              title: "DPA™ DEFECT PROGRESSION ANALYSIS",
              img: "/images/rectina/lg4.png",
              desc: "Create a baseline and follow disease progression over time.",
            },
            {
              id: 5,
              title: "EYESEE™",
              img: "/images/rectina/lg5.png",
              desc: "In the review screen, click on any point during the exam to see the eye position.",
            },
            {
              id: 6,
              title: "VOICE GUIDE",
              img: "/images/rectina/lg6.png",
              desc: "Voice messages assist operator and patient during the examination.",
            },
            {
              id: 7,
              title: "HEAD TRACKING",
              img: "/images/rectina/lg7.png",
              desc: "Live monitoring and automated adjustments throughout exams.",
            },
            // {
            //   id: 8,
            //   title: "OPTIMIZED PERFORMANCE",
            //   img: "/images/rectina/center_logo.png",
            //   desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            // },
          ],
        },
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/PTS 2000.pdf",
          },
        ],
        img: ["/images/rectina/pts-2000.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/Optopol-produkt-308.png",
              "/images/rectina/perislideimg.png",
              "/images/rectina/pts2000left.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/rectina/Picture1.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/Picture2.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/Picture3.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/Picture4.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/Picture5.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "PTS 925W",
    slug: "pts-925w",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "PTS 925W",
        name: "The PTS 925W is our compact perimeter that packs a punch. It provides everything you need in our smallest footprint.",
        main_points: [
          "New 24-2C pattern now available",
          "Goldman III",
          "Voice Guidance",
          "ZETA™, ZETA™ FAST, and ZETA™ Faster",
          "DPA™ Defect Progression Analysis",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/bgpts.png",
          key_feature: [
            {
              id: 1,
              title: "FAST THRESHOLDING",
              img: "/images/rectina/lg1.png", // using common image
              desc: "Using ZETA™ thresholding algorithms, test your patients in under 3 minutes.",
            },
            {
              id: 2,
              title: "EYE TRACKING",
              img: "/images/rectina/lg2.png",
              desc: "Monitor patient pupil movement during examination.",
            },
            {
              id: 3,
              title: "BLINK DETECTION",
              img: "/images/rectina/lg3.png",
              desc: "Our software detects when the eye is closed, and will not present stimuli to the patient.",
            },
            {
              id: 4,
              title: "DPA™ DEFECT PROGRESSION ANALYSIS",
              img: "/images/rectina/lg4.png",
              desc: "Create a baseline and follow disease progression over time.",
            },
            {
              id: 5,
              title: "EYESEE™",
              img: "/images/rectina/lg5.png",
              desc: "In the review screen, click on any point during the exam to see the eye position reviewing the test result.",
            },
            {
              id: 6,
              title: "VOICE GUIDE",
              img: "/images/rectina/lg6.png",
              desc: "Voice messages assist operator and patient during the examination.",
            },
            {
              id: 7,
              title: "HEAD TRACKING",
              img: "/images/rectina/lg7.png",
              desc: "Live monitoring and automated adjustments throughout exams.",
            },
            // {
            //   id: 8,
            //   title: "OPTIMIZED PERFORMANCE",
            //   img: "/images/rectina/center_logo.png",
            //   desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            // },
          ],
        },
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
          },
        ],
        img: ["/images/rectina/pts925.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/perimeter/1.png",
              "/images/rectina/perimeter/2.png",
              "/images/rectina/perimeter/3.png",
              "/images/rectina/perimeter/4.png",
              "/images/rectina/perimeter/5.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/rectina/pic1.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/pic2.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/pic3.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/rectina/pic4.jpg",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const tonometer = [
  {
    id: 1,
    logo: "/images/rectina/csologo.png",
    model: "A900 | F900 | Z800",
    name: "Applanation Tonometers are precision devices for the measurement of IOP.",
    main_points: ["Precise & user friendly", "Easily mounted on Slit-lamp"],
    slug: "a900-f900-z800",
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
      },
    ],
    img: ["/images/rectina/tonoimg.png"],
    video: "",
  },
];

// {
//   id: 2,
//   model: "FT-1000",
//   data: [
//     {
//       id: 1,
//       logo: "/images/rectina/TOMEY.png",
//       model: "FT-1000",
//       name: "Automated Air puff Non-Contact Tonometer",
//       main_points: [
//         "Fully automatic",
//         "Touch screen",
//         "Soft and silent air pulse",
//         "High-speed measurement",
//         //"Soft and silent air pulse",
//       ],
//       additionalInfo: [
//         {
//           id: 1,
//           title: "Brochures",
//           data: "/pdf/brochures/FT-1000.pdf",
//         },
//       ],
//       img: ["/images/rectina/Fttono.png"],

//       video: [
//         {
//           videoSrc: "/images/rectina/tonovideoimg.png",
//           videoTitle: "",
//           videoUrl: "https://youtu.be/CuPHd0V9cgI",
//         },
//       ],
//     },
//   ],
// },
//];

export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    slug: "pachette-4",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHETTE 4",
        name: "The Pachette 4 is a  portable pachymeter, offering quick and accurate corneal thickness measurements for various clinical applications.",
        main_points: [
          "Easy , Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/PACHETTE 4.pdf",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          "/images/rectina/Flap-Mode-for-Pachette-4.png",
          "/images/rectina/Turn-on-Power-Pachette-4.png",
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachmate 2",
    slug: "pachette-2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHMATE 2",
        name: "The Pachmate 2 is a  portable pachymeter, offering quick and accurate corneal thickness measurements for various clinical applications.It is designed for performance, portability and convenience.",
        main_points: [
          "Easy , Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/PACHYMATE 2.pdf",
          },
        ],
        img: ["/images/rectina/Pachmate-2.png"],
        video: [
          {
            videoData: [
              {
                videothumbnail:
                  "/images/rectina/Proven-Accuracy-Pachmate-2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Proven-Accuracy-Pachmate-2",
              },
              {
                videothumbnail: "/images/rectina/Rotation Probe.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Rotation Probe",
              },
              {
                videothumbnail: "/images/rectina/User Friendly.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "User Friendly",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const erg = [
  // {
  //   id: 1,
  //   model: "RETEVAL",
  //   data: [
  {
    id: 1,
    logo: "/images/rectina/cropped-cropped-LKC_logo_RGB_color_no_tagline_mini_400px.png",
    model: "RETEVAL",
    slug: "reteval",
    name: "Enhance your diagnostic capabilities with the RETeval® ERG & VEP device — a powerful aid in the diagnosis and management of retina and optic nerve diseases such as diabetic retinopathy, glaucoma, and inherited retinal dystrophies.",
    main_points: [
      "Simple to use and interpret",
      "Advanced testing with ISCEV compliant",
      "No Corneal contact",
      "No dilation required",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/LKC.pdf",
      },
    ],
    img: ["/images/rectina/RETeval-full-side-view_pupil.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/RETeval-ganzfeld.png",
          "/images/rectina/RETeval-full-side-view_pupil(1).png",
          "/images/rectina/RETeval-front-view-eye.png",
        ],
        videoSrc: [
          {
            src: "/images/rectina/KSP_LKC_Product_19_EDT_results.png",
            title: "Simple to Use",
          },
          {
            src: "/images/rectina/RETeval-with-face-front-ganzfeld.png",
            title: "No Corneal Contact",
          },
        ],
        videoTitle: "Simple To Use",
      },
    ],
  },
  //   ],
  // },
  // {
  //   id: 1,
  //   model: "EP-1000",
  //   data: [
  //     {
  //       id: 1,
  //       logo: "/images/rectina/TOMEY.png",
  //       model: "EP-1000 Multifocal",
  //       name: "EP-1000 is a diagnostic device for a complete examination of Retina function, the visual pathway and optic nerve.",
  //       main_points: [
  //         "Complies with ISCEV recommendations",
  //         "High-intensity LED colour flash and background illumination",
  //         "VEP, ERG, PERG, EOG, Sweep VEP, multi-channel VEP, S-Cone analysis, PERG ratio, VEP children, VEP uncooperative patient",
  //         "Multifocal (mf) ERG and VEP, short M-sequences",
  //       ],
  //       additionalInfo: [
  //         {
  //           id: 2,
  //           title: "Brochures",
  //           data: "/pdf/brochures/EP-1000 New.pdf",
  //         },
  //       ],
  //       img: ["/images/rectina/EP-1000Mf.png"],
  //       // video: [
  //       //   {
  //       //     imggallery: [
  //       //       "/images/rectina/RETeval-full-side-view_pupil.png",
  //       //       "/images/rectina/RETeval-full-side-view_pupil.png",
  //       //       "/images/rectina/RETeval-full-side-view_pupil.png",
  //       //     ],
  //       //     videoSrc: [
  //       //       "/images/rectina/Mask Group 38.png",
  //       //       "/images/rectina/Turn-on-Power-Pachette-4.png",
  //       //     ],
  //       //     videoTitle: "Overview Screen",
  //       //   },
  //       // ],
  //     },
  //   ],
  // },
];

export const scanmate = [
  {
    id: 1,
    logo: "/images/rectina/DGH.png",
    model: "SCANMATE FLEX",
    slug: "scanmate-flex",
    name: "The Scanmate Flex is a portable, ophthalmic ultrasound platform, which enhance practice’s diagnostic capabilities and provide any desired combination of A-Scan, B-Scan, and UBM.",
    main_points: [
      "Portable and USB connectivity",
      "Provides clear imaging of the posterior segment of the eye",
      "Seamless platform",
      "Upgradable to A-scan and UBM",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
        data: "/pdf/brochures/FLEX-LAB-BRCH3-SCANMATE.pdf",
      },
    ],
    img: ["/images/rectina/Scanmate-FLEX.png"],
    video: [
      {
        imggallery: ["/images/rectina/DGH-SHOOT-3-042-UBM.png"],
        videoSrc: "/images/rectina/scanmateflex.png",
        videoTitle: "Upgradable to A-scan and UBM",
        videoData: [
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/1.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/2.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundussss Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/3.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/4.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/5.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
        ],
      },
    ],
  },
];

export const ud = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "UD-800",
    slug: "ud-800",
    name: "The UD-800 is an Ultrasonic B-Scan & UBM, A SCAN  all-in-one ophthalmic device, combining advanced features with ease of use.",
    main_points: [
      "Light & compact",
      "Wide touch screen",
      "Harmonic function",
      "Internal database",
      "Upgradable to A-Scan & UBM",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/UD-800_eng catalog.pdf",
      },
    ],
    img: ["/images/rectina/UD-800_Side_0002.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/UD-800_Side_0002(1).png",
          "/images/rectina/udsecondimg.png",
        ],
        videoSrc: [
          {
            src: "/images/rectina/udimg1.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            title: "How to Use UBM Probe",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
          },
          {
            src: "/images/rectina/udimg.png",
            title: "Product Description",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
          },
        ],
        // videoSrc: "/images/cataract/overview.png",
        videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const fundus = [
  {
    id: 1,
    logo: "/images/rectina/csologo.png",
    model: "COBRA + FUNDUS CAMERA",
    slug: "cobra_fundus_camera",
    name: "Cobra+ is an easy to use, non-mydriatic digital fundus camera.",
    main_points: [
      "High resolution retinal images up to 50° visual field",
      "Multiple wavelength images",
      "Automatic measure of “Cup to disk ratio”",
      "Mosaic function",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/cobra+.pdf",
      },
    ],
    img: ["/images/rectina/fundusimg.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/fundusimg.png",
          "/images/rectina/fundussliderimg.png",
        ],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const Manitrocars = [
  {
    id: 1,
    logo: "/images/rectina/mani.png",
    model: "Mani Trocars & Infusion Cannulas",
    name: "Trocar Blade in the shape of MVR knife provide ultimate sharpness,Valved Infusion cannula for simpler operation to use in Vitrectomy procedures.",
    main_points: ["Less Leakage", "Unique Valved Cannula" , "Advanced Trocars from Mani Japan"],
    slug: "a900-f900-z800",
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
      },
    ],
    img: ["/images/rectina/Mani Trocars.png"],
    video: "",
  },
];
