import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { TiSocialLinkedin } from "react-icons/ti";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="w-full relative h-full md:px-[6.98vw] px-[1.0667vw]">
      <div className="hidden md:block  absolute left-0 right-0 mt-10px-vw w-[87%] border-b-2 opacity-10 border-[#2B2A29] z-10 md:mx-[6.24vw] px-[1.0667vw] md:px-0"></div>
      <div className="md:hidden block absolute left-0 top-0 md:w-[87%] w-[92%] mx-14px-mvw md:mx-[6.24vw]   border-b-2 opacity-10  border-[#2B2A29] z-10"></div>
      <div className="w-full  pt-50px-mvw md:pt-70px-vw   md:py-70px-vw grid md:grid-cols-2 grid-cols-1">
        <div className="flex flex-col relative items-start justify-start md:space-y-50px-vw space-y-20px-mvw md:mt-20px-vw">
          <div className="md:w-[11.93vw] w-82px-mvw">
            <img src="/images/footer/footer_logo.png" alt="footer_logo" />
          </div>
          <div className="md:w-[20vw] w-100px-mvw">
            <img src="/images/footer/footerLogo.png" alt="footer_logo" />
          </div>
          {/* <div className="text-[#2B2A29] md:text-32px-vw md:max-w-[70%] text-18px-mvw  font-poppins font-semibold opacity-80">
            It’s time to find freedom from pain so you can start living again.
          </div> */}
          <div className=" hidden md:flex flex-col items-start gap-40px-vw ">
            <div className="flex items-center">
              <a
                href="mailto: info@myhealthskape.com"
                className="flex items-center  hover:text-[#0081A8] cursor-pointer"
              >
                <div className="border-[#0081A8] flex items-center justify-center border-[0.078vw] md:border-[0.078vw]  rounded-full md:p-[0.573vw] md:py-[0.937vw] ">
                  <img
                    className="w-[6vw] md:w-[2vw] "
                    src="/images/footer/mail.svg"
                    alt="mail"
                    loading="lazy"
                  />
                </div>
                <div className="text-[#2B2A29] hover:text-[#519A7F] md:pl-40px-vw pl-80px-vw font-poppins font-regular md:text-22px-vw">
                  info@myhealthskape.com
                </div>
              </a>
            </div>
            <div className="flex items-center">
              <div className="border-[#0081A8]  md:border-[0.078vw] border-[0.4vw] rounded-full md:p-[0.625vw] p-[9.6vw]">
                <img
                  className="w-[6vw] md:w-[2vw]"
                  src="/images/footer/phone.svg"
                  alt="phone"
                  loading="lazy"
                />
              </div>
              <a
                href="tel:+91-22-26862626"
                className="flex items-center  hover:text-[#0081A8] cursor-pointer"
              >
                <span className="text-[#2B2A29] hover:text-[#519A7F] md:pl-40px-vw md:pr-20px-vw pl-80px-vw font-poppins font-regular md:text-22px-vw">
                  +91-22-26862626
                </span>
                <span className="">|</span>
              </a>
              <a
                href="tel:+91-22-26862828 "
                className="flex items-center  hover:text-[#0081A8] cursor-pointer"
              >
                <span className="text-[#2B2A29] hover:text-[#519A7F] md:pl-20px-vw  md:pr-20px-vw  font-poppins font-regular md:text-22px-vw">
                  26862828
                </span>
                <span className="">|</span>
              </a>
              <a
                href="tel:+91-22- 26862929"
                className="flex items-center  hover:text-[#0081A8] cursor-pointer"
              >
                <span className="text-[#2B2A29] hover:text-[#519A7F] md:pl-20px-vw  font-poppins font-regular md:text-22px-vw">
                  26862929
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="w-full h-full">
          <div className="md:flex  justify-end md:text-[1.04vw] text-[3vw] gap-x-50px-vw items-center hidden md:mr-75px-vw  md:mt-20px-vw">
            <a
              href="https://www.linkedin.com/company/myhealthskape-medicals-private-limited/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              className="opacity-[0.77]  hover:text-[#0081A8] cursor-pointer"
            >
              <img
                src="/images/footer/linkedin.png"
                alt="linkedind"
                className="w-[6vw] md:w-[2vw] h-[6vw] md:h-[2vw] object-contain"
              />
            </a>
            <a
              href="https://youtube.com/@myhealthskapemedicals7385?si=xoXYj2HtKzjyyg4P"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#0081A8] cursor-pointer opacity-[0.77] "
            >
              <img
                src="/images/footer/youtube.png"
                alt="youtube"
                className="w-[6vw] md:w-[2vw] h-[6vw] md:h-[2vw] object-contain"
              />
            </a>
            <a
              href="https://www.facebook.com/p/My-Healthskape-Medicals-Pvt-Ltd-100057432939819/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#0081A8] cursor-pointer opacity-[0.77] "
            >
              <img
                src="/images/footer/facebook.png"
                alt="facebook"
                className="w-[6vw] md:w-[2vw] h-[6vw] md:h-[2vw] object-contain"
              />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#0081A8] cursor-pointer opacity-[0.77] "
            >
              <img
                src="/images/footer/twitter.png"
                alt="twitter"
                className="w-[6vw] md:w-[2vw] h-[6vw] md:h-[2vw] object-contain"
              />
            </a>
            <a
              href="https://www.instagram.com/myhealthskape?igsh=aG9wYjFsbnF0cDhu&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#0081A8] cursor-pointer opacity-[0.77] "
            >
              <img
                src="/images/footer/instagram.svg"
                alt="instagram"
                className="w-[6vw] md:w-[2vw] h-[6vw] md:h-[2vw] object-contain"
              />
            </a>
          </div>

          <div className="w-full flex h-full md:flex-row flex-col md:items-start md:mt-80px-vw items-start justify-evenly md:py-0 py-24px-mvw gap-x-[6.25vw]">
            <div className="flex flex-col items-start gap-y-16px-mvw md:gap-y-40px-vw md:mb-0 mb-32px-mvw">
              <Link
                to="/about-us"
                className=" font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                About Us
              </Link>
              <Link
                to="/products/retina-glaucoma"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Retina & Glaucoma
              </Link>
              <Link
                to="/products/cornea-refractive"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Cornea Refractive
              </Link>
              <Link
                to="/products/cataract"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Cataract
              </Link>
              <Link
                to="/products/primary-diagonostics"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Primary Diagonostics
              </Link>
              <Link
                to="/products/sterilization-indicators"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Sterilization & Indicators
              </Link>
            </div>
            <div className="md:hidden absolute left-0 bottom-1/2 md:w-[87%] w-[92%] mx-14px-mvw md:mx-[6.24vw]   border-b-2 opacity-10  border-[#2B2A29] z-10"></div>
            <div className="flex flex-col items-start md:gap-y-40px-vw gap-y-16px-mvw">
              <Link
                to="/investors"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Investors
              </Link>
              <Link
                to="/privacy-policy"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-conditions"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                Terms & Conditions
              </Link>
              <Link
                to="/faqs"
                className="text-[#2B2A29] font-poppins font-regular md:text-20px-vw text-16px-mvw hover:text-[#519A7F] cursor-pointer"
              >
                FAQs
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* mobile icon and section  */}
      <div className=" md:hidden w-full flex flex-col items-start md:gap-40px-vw gap-20px-mvw ">
        <div className="flex items-center">
          <a
            href="mailto:info@myhealthskape.com"
            className="flex items-center  hover:text-[#0081A8] cursor-pointer"
          >
            <div className="border-[#0081A8] flex items-center justify-center py-9px-mvw  border-[0.6vw] rounded-full   p-7px-mvw">
              <img
                src="/images/footer/mail.svg"
                alt="mail"
                loading="lazy"
                className="w-20px-mvw"
              />
            </div>
            <div className="text-[#2B2A29] hover:text-[#519A7F] md:pl-30px-vw pl-80px-vw font-poppins font-regular text-16px-mvw md:text-22px-vw">
              info@myhealthskape.com
            </div>
          </a>
        </div>
        <div className="flex  items-center md:text-[1.04vw] text-[3vw]">
          <div className="border-[#0081A8] flex flex-shrink-0 border-[0.6vw] rounded-full md:p-12px-vw p-36px-vw">
            <img
              src="/images/footer/phone.svg"
              alt="phone"
              loading="lazy"
              className="w-20px-mvw"
            />
          </div>

          <div className="flex flex-wrap items-center">
            <a
              href="tel:+91-22-26862626"
              className="flex items-center hover:text-[#0081A8] cursor-pointer"
            >
              <div className="whitespace-nowrap hover:text-[#519A7F] md:pl-40px-vw pl-10px-mvw pr-10px-mvw font-poppins font-regular text-16px-mvw md:text-22px-vw">
                +91-22-26862626
              </div>
              <span className="px-2">|</span>
            </a>

            <a
              href="tel:+91-22-26862828"
              className="flex items-center hover:text-[#0081A8] cursor-pointer"
            >
              <div className="text-[#2B2A29] hover:text-[#519A7F] md:pl-40px-vw pl-10px-mvw pr-10px-mvw font-poppins font-regular text-16px-mvw md:text-22px-vw">
                26862828
              </div>
              <span className="px-2">|</span>
            </a>

            <a
              href="tel:+91-22-26862929"
              className="flex items-center hover:text-[#0081A8] cursor-pointer"
            >
              <div className="text-[#2B2A29] hover:text-[#519A7F] md:pl-40px-vw pl-10px-mvw pr-10px-mvw font-poppins font-regular text-16px-mvw md:text-22px-vw">
                26862929
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* mobile icons  */}
      <div className="flex md:hidden items-center text-[#2B2A29] opacity-80 py-30px-mvw text-24px-mvw gap-x-36px-mvw">
        <a
          href="https://www.linkedin.com/company/myhealthskape-medicals-private-limited/?viewAsMember=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TiSocialLinkedin className="text-[#2B2A29] text-32px-mvw" />
        </a>
        <a
          href="https://youtube.com/@myhealthskapemedicals7385?si=xoXYj2HtKzjyyg4P"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillYoutube className="text-[#2B2A29] " />
        </a>
        <a
          href="https://www.facebook.com/p/My-Healthskape-Medicals-Pvt-Ltd-100057432939819/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-[#2B2A29]" />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsTwitter className="text-[#2B2A29]" />
        </a>
        <a
          href="https://www.instagram.com/myhealthskape?igsh=aG9wYjFsbnF0cDhu&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
          className=" cursor-pointer text-[#2B2A29] "
        >
          <img
            src="/images/footer/instagram.svg"
            alt="instagram"
            className="w-full h-full object-contain"
          />
        </a>
      </div>

      {/* Border above Togglehead */}
      <div className="absolute left-0 md:w-[87%] w-[92%] mx-16px-mvw md:mx-[6.24vw]   border-b-2 opacity-10  border-[#2B2A29] z-10"></div>

      <div className="flex md:flex-row flex-col my-14px-mvw  md:my-0 items-start md:items-center justify-between text-[#2B2A29]  font-poppins md:text-14px-vw text-12px-mvw gap-y-20px-vw md:gap-y-0 md:py-32px-vw">
        <div>
          {`My HealthSkape Medicals Pvt. Ltd. © All Rights Reserved - ${year}`}
        </div>
        <div>
          Crafted by{" "}
          <a
            href="https://www.togglehead.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#519A7F]"
          >
            <span>Togglehead</span>{" "}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
