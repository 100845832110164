import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import { opticalBiometerData } from "../../../Data/Cataract";
import BottomSlider from "../../slider/BottomSlider";
import ImageSlider from "../../slider/ImageSlider";
import Popup from "../../common/Popup";

const OpticalBiometer = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
    setProductData({
      category: "Cataract",
      subCategory: "Optical Biometer",
      product: item.model,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Optical Biometer"}
        centerHeading={"Optical Biometer"}
      />

      <section
        className="w-full md:py-[7.81vw]  py-60px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {opticalBiometerData.map((item) => (
          <div key={item.id} className="w-full ">
            <div className="grid md:grid-cols-2  md:px-[7.29vw] gap-y-40px-mvw md:gap-y-0 px-16px-mvw grid-cols-1 md:pb-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[2vw] max-h-[3.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>

                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((point, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw"
                  >
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {point}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw">
                {item.img.map((img, index) => (
                  <div key={index}>
                    <img
                      src={img}
                      alt=""
                      className="w-auto max-h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full  gap-x-16px-vw md:pt-[3vw] md:px-[7.29vw] py-60px-mvw  flex px-16px-mvw justify-center  gap-y-16px-mvw md:gap-y-0 flex-col md:flex-row items-center md:py-60px-vw">
              <DownloadCard
                data={item.additionalInfo}
                onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                productData={{
                  category: "Cataract",
                  subCategory: "Optical Biometer",
                  product: item.model,
                }}
              />
            </div>

            {/* {item.video && (
              <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] ">
                <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                  IMAGE & VIDEO GALLERY
                </h1>

                <ImageSlider data={item.imgGallery} />

               
                <div className="absolute left-0 md:px-[7.29vw] md:w-full md:mb-[7vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                <BottomSlider slides={item.video} />
              </div>
            )} */}

            {item.video &&
              item.video.map((videoItem, index) => (
                <div
                  key={index}
                  className="w-full h-full relative  md:px-[7.29vw] px-16px-mvw  md:pt-60px-vw"
                >
                  <div className="md:mt-0px-vw relative">
                    <h1 className="font-raleway text-center w-full   font-bold md:text-32px-vw   text-24px-mvw text-[#2B2A29]">
                      PRODUCT GALLERY
                    </h1>

                    <div>
                      <ImageSlider data={item.imgGallery} />
                    </div>
                    {/* <div className="hidden md:flex items-center md:pt-[5vw] justify-center">
                      {item.imgGallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:max-h-[24.23vw] md:mr-[7vw] object-cover"
                        />
                      ))}
                    </div> */}

                    {/* <div className="absolute left-0 md:px-[7.29vw] md:w-full md:mb-[6vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                    {/* <h1 className="font-raleway text-center font-bold md:text-32px-vw md:py-80px-vw pb-40px-mvw   text-24px-mvw text-[#2B2A29]">
                      IMAGE & VIDEO GALLERY
                    </h1> */}

                    {/* Thumbnail */}

                    {/* {!isPlaying ? (
                      <>
                        <div className="relative w-full flex items-center justify-center">
                          <img
                            src={videoItem.videothumbnail} 
                            alt="video thumbnail"
                            className="md:max-w-[42.24vw] block"
                          />
                          <div
                            className="absolute cursor-pointer inset-0 flex items-center justify-center"
                            onClick={handlePlay} 
                          >
                            <img
                              src="/icon/playIcon.png" 
                              alt="play icon"
                              className="md:w-73px-vw"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                     
                      <iframe
                        className="md:max-w-[80vw] block"
                        width="700" // Increase the width here
                        height="450" // Adjust the height accordingly
                        src={`https://www.youtube.com/embed/${videoItem.videoSrc
                          .split("/")
                          .pop()}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )} */}

                    {/* <div className="md:pt-20px-vw pt-12px-mvw text-center text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                      {videoItem.videoTitle}
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default OpticalBiometer;
