import { BiChevronRight } from "react-icons/bi";
import React from "react";
import "./Button.css";

const Button = ({ title, onClick, customClasses }) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center text-center px-[4.267vw] py-[2.133vw] md:p-0 md:pl-[1.146vw] md:pr-[1.042vw] py-[3.30vw] md:py-[1.04vw] border border-solid border-[#2B2A29] rounded-[8vw] md:rounded-[3.56vw] 
      opacity-100 backdrop-filter backdrop-blur-[10px] bg-white bg-opacity-25
      font-poppins font-medium transition-all md:text-[1vw] text-[3.2vw] duration-300 ease-in-out hover:bg-opacity-50 hover:border-[#000] ${customClasses}`}
    >
      {title}
      <span className="relative flex items-center ml-[5.33vw] md:ml-[1.04vw] arrow-container">
        <BiChevronRight
          className="arrow-icon transition-transform duration-500 ease-in-out transform md:text-[1.5vw] text-[3.2vw]"
          // size={30}
        />
      </span>
    </button>
  );
};

export default Button;
