import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export const useProductFromSlug = (productData, defaultIndex = 0) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to format the slug
  const getSlugFromIndex = (index) => {
    if (!productData || !productData[index]?.model) return null;

    return productData[index].model
      .trim()
      .replace(/[\|\/]/g, " ") // Replace | and / with spaces
      .replace(/\s*\+\s*/g, "") // Remove + if surrounded by spaces or standalone
      .replace(/\s*&\s*/g, "") // Remove & if surrounded by spaces or standalone
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .toLowerCase(); // Replace + with "plus"
  };

  useEffect(() => {
    if (slug) {
      // Find the product based on the slug in the URL
      let foundIndex = -1;
      productData.forEach((category, index) => {
        const modelSlug = getSlugFromIndex(index);
        if (modelSlug === slug) {
          foundIndex = index;
        }
      });

      if (foundIndex !== -1) {
        setActiveIndex(foundIndex);
      }
    } else {
      // No slug in the URL - navigate to the default slug
      const currentPath = location.pathname;
      const defaultSlug = getSlugFromIndex(defaultIndex);

      if (defaultSlug && !currentPath.endsWith(defaultSlug)) {
        navigate(`${currentPath}/${defaultSlug}`, { replace: true });
      }
    }
  }, [slug, productData, navigate, location.pathname, defaultIndex]);

  // Custom setter that also updates the URL while preserving the parent path
  const setActiveIndexWithNavigation = (newIndex) => {
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex);
      const newSlug = getSlugFromIndex(newIndex);

      if (newSlug) {
        // Preserve the full path structure including "oct"
        const pathParts = location.pathname.split("/");
        // Replace only the last part (slug)
        pathParts[pathParts.length - 1] = newSlug;
        navigate(pathParts.join("/"));
      }
    }
  };

  return [activeIndex, setActiveIndexWithNavigation];
};
