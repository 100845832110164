import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import AboutTopSection from "../../components/aboutus/AboutTopSection";
import {
  data,
  partnerslogomobile,
  sustainabilityData,
} from "../../Data/DummyData";
import OurHistory from "../../components/aboutus/OurHistory";
import Vision from "../../components/aboutus/Vision";
import Achievements from "../../components/aboutus/Achievements";
import OurTeam from "../../components/aboutus/OurTeam";
import OurPartners from "../../components/home/OurPartners";
import SectionHeading from "../../components/common/SectionHeading";
import ParaText from "../../components/common/ParaText";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Aos from "aos";
import Marquee from "react-marquee-slider";

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Function to update the isMobile state on resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const [isPaused, setIsPaused] = useState(false);
  const [velocity, setVelocity] = useState(40);
  const PartnersImage = [
    { id: 1, imgsrc: "/images/alliance/img3.png" },
    { id: 2, imgsrc: "/images/alliance/img1.png" },
    { id: 3, imgsrc: "/images/alliance/img2.png" },
    { id: 4, imgsrc: "/images/alliance/img16.png" },
    { id: 5, imgsrc: "/images/alliance/img5.png" },
    { id: 6, imgsrc: "/images/alliance/img6.png" },
  ];
  const Partners = [
    { id: 1, imgsrc: "/images/alliance/img11.png" },
    { id: 2, imgsrc: "/images/alliance/img4.png" },
    
    { id: 4, imgsrc: "/images/alliance/img8.png" },
    { id: 5, imgsrc: "/images/alliance/img9.png" },
    { id: 6, imgsrc: "/images/alliance/img10.png" },
    { id: 2, imgsrc: "/images/alliance/img13.png" },
  ];
  const Partnerslogo = [
    
    // { id: 1, imgsrc: "/images/alliance/img12.png" },
    
    // { id: 3, imgsrc: "/images/alliance/img14.png" },
    // { id: 4, imgsrc: "/images/alliance/img15.png" },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in-out",
      duration: 800,
      once: false,
      offset: 100,
    });
  }, []);

  useEffect(() => {
    // Adjust velocity based on window width
    const handleResize = () => {
      if (window.innerWidth <= 375) {
        // Mobile breakpoint
        setVelocity(20); // Decrease speed for mobile
      } else {
        setVelocity(40); // Default speed for larger screens
      }
    };

    handleResize(); // Set initial velocity
    window.addEventListener("resize", handleResize); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full ">
      <CategoryTopSection
        breadcrumb={"Home | About Us"}
        centerHeading={"About Us"}
      />

      <section className="w-full md:px-0 px-16px-mvw py-60px-mvw md:pt-[6.25vw] md:pb-[3vw]">
        <AboutTopSection heading={"ABOUT US"} desc={data} />
        <OurHistory />
        <Vision />
        <Achievements />
        <OurTeam />
        <div className="md:py-[6.25vw] w-full md:px-[7.29vw] ">
          {/* <AboutTopSection
            heading={"SUSTAINABILITY"}
            desc={sustainabilityData}
            CustomCss={"order-2 "}
          />   */}

          <div className="md:hidden  w-full">
            <div className="text-center">
              <SectionHeading
                title={"OUR PARTNERS"}
                customCss={"uppercase mb-40px-mvw md:mt-40px-vw"}
              />
            </div>

            <div className="grid grid-cols-2  w-full z-10 ">
              {partnerslogomobile.map((item, index, array) => (
                <div
                  key={item.id}
                  className={`flex items-center justify-center p-4 border-opacity-20 border-[#2B2A29] border-solid ${
                    index % 2 === 0 ? "border-b border-r" : "border-b"
                  } ${
                    index === array.length - 1 ? "border-b-0" : ""
                  } min-h-[30vw]`}
                >
                  <img
                    src={item.imgsrc}
                    alt={`Partner ${item.id}`}
                    className="w-full h-full object-contain opacity-100"
                  />
                </div>
              ))}
            </div>
          </div>

          <div
            className={` w-full   pt-60px-mvw md:pt-0 grid grid-cols-1 gap-y-40px-mvw md:gap-y-0 md:grid-cols-2 gap-x-80px-vw`}
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="150"
          >
            <div
              className={`w-full h-full flex flex-col items-start justify-center  ${"order-2"}`}
            >
              <SectionHeading title={"SUSTAINABILITY"} />
              <ParaText
                paratext={sustainabilityData}
                CustomCss={"md:mt-40px-vw mt-20px-mvw"}
              />

              <div className=" md:hidden mt-32px-mvw md:mt-0 block w-full rounded-[1.5vw]">
                <img src="/images/about/plant.png" alt="plant" />
              </div>

            
            </div>
            <div className="hidden md:block w-full rounded-[1.5vw]">
              <img src="/images/about/plant.png" alt="plant" />
            </div>
          </div>
        </div>

        <section
          className={`w-full relative hidden md:grid grid-cols-1  md:pl-0 md:pr-0`}
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="150"
        >
          <div className="text-center">
            <SectionHeading
              title={"ALLIANCE PARTNERS"}
              customCss={"uppercase my-40px-mvw md:my-40px-vw"}
            />
          </div>

          {/* Marquee Section for Mobile */}

          {/* Desktop Section */}
          <div
            className="relative md:px-[7.29vw]  overflow-hidden  w-full border-b-2 border-opacity-10 border-[#2B2A29] md:py-65px-vw md:gap-x-[2.9vw] flex items-center py-35px-mvw bg-opacity-15 z-10"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {PartnersImage.map((item) => (
              <div
                key={item.id}
                className="flex-shrink-0 md:max-w-[11.56vw] md:max-h-[7vw] transition duration-300 ease-in-out"
              >
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="w-full h-auto object-contain opacity-60 filter grayscale hover:grayscale-0 mix-blend-luminosity hover:opacity-100 hover:mix-blend-normal"
                />
              </div>
            ))}
          </div>

          <div
            className="relative overflow-hidden md:px-[7.29vw] md:py-65px-vw  border-opacity-10 border-[#2B2A29] md:gap-x-[2.9vw] flex items-center  py-35px-mvw bg-opacity-15 z-10  "
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {Partners.map((item) => (
              <div
                key={item.id}
                className="flex-shrink-0 md:max-w-[11.56vw] md:max-h-[7vw]"
              >
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="w-full h-auto opacity-60 filter grayscale hover:grayscale-0 mix-blend-luminosity hover:opacity-100 hover:mix-blend-normal"
                />
              </div>
            ))}
          </div>

          {/* <div
            className="relative overflow-hidden  md:px-[7.29vw] md:py-65px-vh md:gap-x-[2.9vw] flex items-center  py-35px-mvw bg-opacity-15 z-10 "
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            {Partnerslogo.map((item) => (
              <div
                key={item.id}
                className="flex-shrink-0 md:max-w-[11.56vw] md:max-h-[7vw] "
              >
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className=" w-full h-auto object-contain opacity-60 filter grayscale hover:grayscale-0 mix-blend-luminosity hover:opacity-100 hover:mix-blend-normal"
                />
              </div>
            ))}
          </div> */}

          {/* Repeat for other sections if needed */}
        </section>
      </section>
    </main>
  );
};

export default AboutUs;
