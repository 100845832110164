export const topographer = [
  {
    id: 1,
    model: "SIRIUS +",
    slug: "sirius-plus",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "SIRIUS +",
        name: "SIRIUS+ is a multi-functional topographer combining Placido disk and Scheimpflug tomography, with Phoenix software for detecting and analyzing Dry Eyes and managing patient data.",
        main_points: [
          "Measurements up to 100 HR corneal sections.",
          "Various maps (Front & Back of the cornea)",
          "Early Keratoconus screening with ABCD grading scale",
          "Ambrosio pachymetry progression map",
          "Corneal aberrometer",
          "Glaucoma screening",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Sirius+.pdf",
          },
        ],
        img: ["/images/cornea/ant2.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Compact Setup",
            videoData: [
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Aberrometery",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Glaucoma Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "IOL Calculation",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "IRCS",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pupillography",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "ANTARES +",
    slug: "antares-plus",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "ANTARES +",
        name: "Antares + is a multi-functional corneal Placido topographer with dedicated software for detecting and analyzing Dry Eye.",
        main_points: [
          "Various maps",
          "Keratoconus screening",
          "Videokeratoscopy",
          "Pupillography",
          "Contact lens application",
          "Dry eye report",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Antares +.pdf",
          },
        ],
        img: ["/images/cornea/ant1.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/Topographer/Antares/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Dry Eye Report",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pupillography",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Videokeratoscope",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Contact Lens Application",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    model: "TMS-4N",
    slug: "tms-4n",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "TMS-4N",
        name: "TMS-4N is a Corneal Placido Topographer with comprehensive software: Single, Dual, Multiple and  customize map with favorite scale, map type and so on.",
        main_points: [
          "Built-in LCD screen",
          "Various maps",
          "Keratoconus screening",
          "Fourier analysis",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/TMS-4N.pdf",
          },
        ],
        img: ["/images/cornea/ant3.png"],
      },
    ],
  },
];

export const microkeratome = [
  {
    id: 1,
    logo: "/images/refractive/logo-moria-white.png",
    model: "Moria Evo 3e console with SBK set",
    slug: "moria-evo",
    name: "Moria Evo 3e 's console features dual high-performance pumps, slow vacuum release, and a backup battery for safe, uninterrupted use.",
    main_points: [
      "Fastest flap creation ( 4 sec)",
      "Predictable thin and Planer flap",
      "Excellent stromal surface smoothness",
      "Excellent safety profile",
      "Very fast visual recovery",
      "Excellent quality of vision",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/Moria OUP-SBK-Brochure.pdf",
      },
    ],
    img: ["/images/cornea/mor1.png"],
    video: [
      {
        imggallery: ["/images/refractive/Scroll Group 1.png"],
        videoSrc: "/images/cornea/corneavideoimg.png",
        videoTitle: "SBK Surgical Process",
        videoUrl: "/Cornea2/Microkeratome/v1.wmv",
      },
    ],
    images: [
      "/Cornea2/Microkeratome/1.png",
      "/Cornea2/Microkeratome/2.png",
      "/Cornea2/Microkeratome/3.png",
      "/Cornea2/Microkeratome/5.png",
      "/Cornea2/Microkeratome/6.png",
      "/Cornea2/Microkeratome/7.png",
      "/Cornea2/Microkeratome/8.png",
      "/Cornea2/Microkeratome/9.png",
      "/Cornea2/Microkeratome/11.png",
      "/Cornea2/Microkeratome/12.png",
      "/Cornea2/Microkeratome/13.png",
      "/Cornea2/Microkeratome/14.png",
      "/Cornea2/Microkeratome/15.png",
    ],
  },
];

export const asoct = [
  {
    id: 1,
    model: "MS-39",
    slug: "ms-39",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "MS-39 ",
        name: "MS-39 is the most advanced device for the analysis of the anterior segment of the eye. MS-39 combines Placido disk corneal topography, with high resolution OCT-based anterior segment tomography.",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Crystalline biometery",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
          "Advanced analysis of Tear film",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/MS-39-ASOCT-CSO.pdf",
          },
        ],
        img: ["/images/cornea/asimg.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Aberrometry",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Sections",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Crustalline Biometry",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Epithelium Thickness",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Summary",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "MS-39 Techinical Features",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "CASIA2",
    slug: "casia2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "CASIA2",
        name: "The CASIA 2 AS-OCT is a flagship anterior segment imaging system designed for precise and comprehensive evaluation of the cornea, anterior chamber and glaucoma structure. This high resolution , non contact imaging system enhances clinical diagnostic and surgical planning, making it the most advanced option in its category.",
        main_points: [
          "Application for cataract/glaucoma/cornea surgery",
          "Epithelium mapping",
          "Glaucoma angle analysis",
          "Advanced imaging at high resolution and deep scanning depth (13 mm)",
          "Lens shape analysis and trend analysis",
          "Phakic IOL simulation",
          "Pre- and Post-Op ICL function",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/CASIA 2 new.pdf",
          },
        ],
        img: ["/images/cornea/asimg1.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "HR Raster Scan",
              },

              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Lens Analysis",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Post Op Cataract Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pre Op Cataract Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "STAR 360 Degree Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "STAR 360 Degree Detailed Report",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/7.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Trend Analysis",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const keratoplasty = [
  {
    id: 1,
    model: "DSAEK + ALTK SYSTEM",
    slug: "dsaek-altk-system",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "Moria Evo 3e console with DSAEK + ALTK  set ",
        name: "Moria Evo 3e 's console features dual high-performance pumps, slow vacuum release, and a backup battery for safe, uninterrupted use.",
        main_points: [
          "Linear system",
          "High degree of accuracy & reproducibility",
          "Range of pre-calibrated single use heads",
          "For Ultra-Thin DSAEK , as well as conventional DSAEK",
          "Very fast visual recovery",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/Moria DSAEK Brochure.pdf",
          },
        ],
        img: ["/images/cornea/kerimg1.png"],
        video: [
          {
            imggallery: [
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/11.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/12.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/13.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/14.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/15.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/16.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/17.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/18.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/19.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/20.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/21.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/22.png",
            ],
            videoData: [
              {
                videothumbnail: "",
                title: "CBM ALTK+DSAEK System",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "",
                title: "UT DSAEK Single Pass Short",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "VACUUM TREPHINE & PUNCH",
    slug: "vacuum-trephine-punch",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "Vacuum Trephine & Punch for PK & DALK Procedures",
        name: "A Vacuum Trephine and Punch is a surgical instrument used in PK and DALK procedures to precisely remove a circular corneal tissue, facilitating corneal transplantation, thus reducing shape disparity and postoperative astigmatism.",
        main_points: [
          "A precise and centred positioning",
          "A vertical cut",
          "Ergonomic design",
          "Adjustable & Non-adjustable depth",
          "Controlled and safe",
          "2-piece design with an Ultra-sharp pre-loaded blade",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
          },
        ],
        img: ["/images/cornea/kerimg.png"],
        video: [
          {
            imggallery: [
              "/images/refractive/Vacuum-punch.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Vacuum-punch.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/11.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/12.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/13.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/14.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const dryice = [
  {
    id: 1,
    model: "IDRA",
    slug: "idra",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "IDRA",
        name: "Idra is the innovative dry eye diagnostic for for individual tear film analysis, enabling a rapid and detailed structural assessment of tear composition",
        main_points: [
          "Quick-Accurate- Non-invasive",
          "15 sec of acquisition in fully automatic mode",
          "Integrated and easy to use",
          "Evaluation of all tear film layers",
          // "Optimal working conditions for the surgeon",
          // "Robust steel chassis with four double castors",
          // "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/IDRADEM100DSLC200ACTIVA_brochure_eng.pdf",
          },
        ],
        img: [
          "/images/cornea/dry.png",
          // "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/Cornea2/DryEye/IDRA/11.png",
              "/Cornea2/DryEye/IDRA/12.png",
              "/Cornea2/DryEye/IDRA/13.png",
              "/Cornea2/DryEye/IDRA/14.png",
              "/Cornea2/DryEye/IDRA/15.png",
            ],

            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/IDRA/2.png",
                title: "Product Application",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "/Cornea2/DryEye/IDRA/1.png",
                title: "Quick-Accurate- Non-invasive",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  // {
  //   id: 2,
  //   model: "ACTIVA",
  //   data: [
  //     {
  //       id: 1,
  //       logo: "/images/refractive/SBM.png",
  //       model: "ACTIVA",
  //       name: "Dry Eye treatment device",
  //       main_points: [
  //         "Safe and effective",
  //         "Long lasting relief",
  //         "Innovative eye mask",
  //         "Fast and painless",
  //         "Easily sanitized and no gel required",
  //       ],
  //       additionalInfo: [
  //         {
  //           id: 2,
  //           title: "Brochures",
  //           data: "/pdf/brochures/ACTIVA_MED brochure_eng.pdf",
  //         },
  //       ],
  //       img: ["/images/cornea/active.png"],
  //       video: [
  //         {
  //           imggallery: [
  //             "/images/cornea/active(1).png",
  //             "/images/refractive/Activa_2.png",
  //           ],
  //           videoData: [
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Activa/1.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Activa/2.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Activa/3.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "",
  //             },
  //           ],
  //           videoSrc: "/images/refractive/_DSC0149.png",
  //           videoTitle: "Crustalline Biometery",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    id: 3,
    model: "TEARCHECK",
    slug: "tearcheck",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "TEARCHECK",
        name: "Tearcheck is an comprehensive, non-invasive tear film analysis system that helps diagnose and monitor dry eye disease.",
        main_points: [
          "Innovative",
          "9 exams for smart analysis",
          "Simple and Fast",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/TEARCHECK & TABLE.pdf",
          },
        ],
        img: ["/images/cornea/tear.png"],
        video: [
          {
            imggallery: [
              "/Cornea2/DryEye/Tearcheck/11.png",
              "/Cornea2/DryEye/Tearcheck/12.png",
              "/Cornea2/DryEye/Tearcheck/13.png",
              "/Cornea2/DryEye/Tearcheck/14.png",
              "/Cornea2/DryEye/Tearcheck/15.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0307",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0240_compressed",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0193",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0163",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0149",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC-1",
              },
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  // {
  //   id: 4,
  //   model: "E-EYE",
  //   data: [
  //     {
  //       id: 1,
  //       logo: "/images/cornea/ESW.png",
  //       model: "E-EYE",
  //       name: "Dry Eye treatment device",
  //       main_points: [
  //         "IRPL technology",
  //         "Simple and Fast",
  //         "Instant improvement",
  //         "Long lasting",
  //         "Quantified efficiency",
  //         "Safe & tailor-made",
  //       ],
  //       additionalInfo: [
  //         {
  //           id: 2,
  //           title: "Brochures",
  //           data: "/pdf/brochures/e-eye.pdf",
  //         },
  //       ],
  //       img: ["/images/cornea/eye.png"],
  //       video: [
  //         {
  //           imggallery: ["/Cornea2/DryEye/EEye/11.png"],
  //           videoSrc: "/images/refractive/_DSC0149.png",
  //           videoData: [
  //             {
  //               videothumbnail: "/Cornea2/DryEye/EEye/1.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "11",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/EEye/2.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "13",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/EEye/3.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "Mechanism-of-Action",
  //             },
  //           ],
  //           // videoTitle: "Crustalline Biometery",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const treatment = [
  // {
  //   id: 1,
  //   model: "IDRA",
  //   data: [
  //     {
  //       id: 1,
  //       logo: "/images/refractive/SBM.png",
  //       model: "IDRA",
  //       name: "Dry Eye Diagnosis",
  //       main_points: [
  //         "Quick-Accurate- Non-invasive",
  //         "15 sec of acquisition in fully automatic mode",
  //         "Integrated and easy to use",
  //         "Evaluation of all tear film layers",
  //         // "Optimal working conditions for the surgeon",
  //         // "Robust steel chassis with four double castors",
  //         // "IOL calculation with Ray tracing",
  //       ],
  //       additionalInfo: [
  //         {
  //           id: 2,
  //           title: "Brochures",
  //           data: "/pdf/brochures/IDRADEM100DSLC200ACTIVA_brochure_eng.pdf",
  //         },
  //       ],
  //       img: [
  //         "/images/cornea/dry.png",
  //         // "/images/refractive/Adjustable-vacuum-Trephine.png",
  //       ],
  //       video: [
  //         {
  //           imggallery: [
  //             "/Cornea2/DryEye/IDRA/11.png",
  //             "/Cornea2/DryEye/IDRA/12.png",
  //             "/Cornea2/DryEye/IDRA/13.png",
  //             "/Cornea2/DryEye/IDRA/14.png",
  //             "/Cornea2/DryEye/IDRA/15.png",
  //           ],

  //           videoData: [
  //             {
  //               videothumbnail: "/Cornea2/DryEye/IDRA/2.png",
  //               title: "Product Application",
  //               description:
  //                 "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/IDRA/1.png",
  //               title: "Quick-Accurate- Non-invasive",
  //               description:
  //                 "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  //             },
  //           ],
  //           videoSrc: "/Cornea2/DryEye/IDRA/1.png",
  //           videoTitle: "Crustalline Biometery",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    id: 4,
    model: "E-EYE",
    slug: "e-eye",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "E-EYE",
        name: "E-eye is an advanced, non-invasive treatment for dry eye disease, specifically targeting MGD and tear film instability, using Intense Pulsed Light (IPL) therapy.",
        main_points: [
          "IRPL technology",
          "Simple and Fast",
          "Instant improvement",
          "Long lasting",
          "Quantified efficiency",
          "Safe & tailor-made",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
          },
        ],
        img: ["/images/cornea/eye.png"],
        video: [
          {
            imggallery: ["/Cornea2/DryEye/EEye/11.png"],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/EEye/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "11",
              },
              {
                videothumbnail: "/Cornea2/DryEye/EEye/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "13",
              },
              {
                videothumbnail: "/Cornea2/DryEye/EEye/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Mechanism-of-Action",
              },
            ],
            // videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "ACTIVA",
    slug: "activa",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "ACTIVA",
        name: "Activa is an innovative non-invasive eye mask for dry eye treatmenr that provides a complete heating and automatic squeezing technology that is effective on MGD.",
        main_points: [
          "Safe and effective",
          "Long lasting relief",
          "Innovative eye mask",
          "Fast and painless",
          "Easily sanitized and no gel required",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/ACTIVA_MED brochure_eng.pdf",
          },
        ],
        img: ["/images/cornea/active.png"],
        video: [
          {
            imggallery: [
              "/images/cornea/active(1).png",
              "/images/refractive/Activa_2.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/Activa/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Activa/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Activa/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  // {
  //   id: 3,
  //   model: "TEARCHECK",
  //   data: [
  //     {
  //       id: 1,
  //       logo: "/images/cornea/ESW.png",
  //       model: "TEARCHECK",
  //       name: "Dry Eye Diagnosis",
  //       main_points: [
  //         "Innovative",
  //         "9 exams for smart analysis",
  //         "Simple and Fast",
  //       ],
  //       additionalInfo: [
  //         {
  //           id: 2,
  //           title: "Brochures",
  //           data: "/pdf/brochures/TEARCHECK & TABLE.pdf",
  //         },
  //       ],
  //       img: ["/images/cornea/tear.png"],
  //       video: [
  //         {
  //           imggallery: [
  //             "/Cornea2/DryEye/Tearcheck/11.png",
  //             "/Cornea2/DryEye/Tearcheck/12.png",
  //             "/Cornea2/DryEye/Tearcheck/13.png",
  //             "/Cornea2/DryEye/Tearcheck/14.png",
  //             "/Cornea2/DryEye/Tearcheck/15.png",
  //           ],
  //           videoData: [
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/1.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC0307",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/2.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC0240_compressed",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/3.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC0193",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/4.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC0163",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/5.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC0149",
  //             },
  //             {
  //               videothumbnail: "/Cornea2/DryEye/Tearcheck/6.png",
  //               videoUrl: "https://youtu.be/CuPHd0V9cgI",
  //               videoTitle: "_DSC-1",
  //             },
  //           ],
  //           videoSrc: "/images/refractive/_DSC0149.png",
  //           videoTitle: "Crustalline Biometery",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const specular = [
  {
    id: 1,
    logo: "/images/cornea/TOMEY.png",
    model: "EM-4000",
    slug: "em-4000",
    name: "EM-4000 specular microscope offers fast and precise non-contact examination of the corneal endothelium, with automatic alignment, measurement, and analysis in just 4 seconds per eye.",
    main_points: [
      "Fully automatic with touch screen",
      "13 measurement area",
      "L-count, Trace, Core method, Dark Area analysis",
      "Counts more than 300 cells",
      "Integrated Non-contact pachy",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
        data: "/pdf/brochures/EM-4000.pdf",
      },
    ],
    img: ["/images/cornea/specular.png"],
    video: [
      {
        imggallery: [
          "/images/refractive/_0-3-EM_4000_3_a.png",
          "/images/refractive/EM_4000_2.png",
          "/images/refractive/EM_4000_3.png",
        ],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    slug: "pachette-4",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHETTE 4",
        name: "The Pachette 4 is a  portable pachymeter, offering quick and accurate corneal thickness measurements for various clinical applications.",
        main_points: [
          "Easy , Fast and Accurate ",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/PACHETTE 4.pdf",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          {
            imggallery: ["/images/rectina/Pachette 4(1).png"],

            videoData: [
              {
                videothumbnail: "/images/cornea/corneaimg.png",
                title: "Flap-Mode-for-Pachette-4n",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "/images/cornea/corneaimg2.png",
                title: "Turn-on-Power-Pachette-4",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachmate 2",
    slug: "pachmate-2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHMATE 2",
        name: "The Pachmate 2 is a  portable pachymeter, offering quick and accurate corneal thickness measurements for various clinical applications.It is designed for performance, portability and convenience.",
        main_points: [
          "Easy , Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/PACHYMATE 2.pdf",
          },
        ],
        img: ["/images/rectina/Pachmate-2.png"],

        video: [
          {
            imggallery: ["/images/rectina/Pachmate-2(1).png"],

            videoData: [
              {
                videothumbnail:
                  "/images/rectina/Proven-Accuracy-Pachmate-2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Proven-Accuracy-Pachmate-2",
              },
              {
                videothumbnail: "/images/rectina/Rotation Probe.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Rotation Probe",
              },
              {
                videothumbnail: "/images/rectina/User Friendly.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "User Friendly",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const scanmatecornea = [
  {
    id: 1,
    logo: "/images/rectina/DGH.png",
    model: "SCANMATE FLEX",
    slug: "scanmate_flex",
    name: "The Flex UBM probe is indispensable when obtaining high resolution images of the anterior segment of the eye, including images of structures concealed by the iris or corneal opacities.",
    main_points: [
      "Portable and USB connectivity",
      "Provides clear imaging of the posterior segment of the eye",
      "Seamless platform",
      "Upgradable to A-scan and UBM",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
        data: "/pdf/brochures/FLEX-LAB-BRCH3-SCANMATE.pdf",
      },
    ],
    img: ["/images/cornea/ubmimg.png"],
    video: [
      {
        imggallery: [
          "/images/cornea/tubeimg.png",
          "/images/cornea/ubmimg(1).png",
        ],
        videoSrc: "/images/rectina/Scanmate FLEX.png",
        videoTitle: "Upgradable to A-scan and UBM",
        videoData: [
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/1.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/2.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundussss Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/3.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/4.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/5.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
        ],
      },
    ],
  },
];

export const udcornea = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "UD-800",
    slug: "ud-800",
    name: "UD-800 A/B scanner is an ultrasonic diagnostic device used for ophthalmic imaging and measurements.",
    main_points: [
      "Light & compact",
      "Wide touch screen",
      "Harmonic function",
      "Internal database",
      "Upgradable to A-Scan & UBM",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/UD-800_eng catalog.pdf",
      },
    ],
    img: ["/images/rectina/UD-800_Side_0002.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/UD-800_Side_0002(1).png",
          "/images/rectina/udsecondimg.png",
        ],
        videoSrc: [
          {
            src: "/images/rectina/udimg1.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            title: "How to Use UBM Probe",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
          },
          {
            src: "/images/rectina/udimg.png",
            title: "Product Description",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
          },
        ],
        // videoSrc: "/images/cataract/overview.png",
        videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];
