import React, { useState, useEffect } from "react";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import Aos from "aos";

const TopSection = () => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  // Array of images and content
  const slides = [
    {
      id: 1,
      image: "/images/home/doctor.png",
      text: "Bringing Your World Into Focus Dummy Content",
      buttonLink: "/about-us",
    },
    {
      id: 2,
      image: "/images/home/doctor.png",
      text: "Healthcare at Your Fingertips Dummy Content",
      buttonLink: "/about-us",
    },
    {
      id: 3,
      image: "/images/home/doctor.png",
      text: "Connecting You with the Best Dummy Content",
      buttonLink: "/about-us",
    },
  ];

  // State to track the current slide
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  // Autoplay effect
  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000);
    return () => clearInterval(slideInterval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  const scrollToSection = () => {
    window.scrollBy({
      top: window.innerHeight*1.3, 
      behavior: "smooth", 
    });
  };

  return (
    <div
      className="w-full md:min-h-[56.25vw]    md:pl-[6.82vw] grid md:grid-cols-2 grid-cols-1 relative"
      style={{
        backgroundImage: `url("/images/home/Home_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Left section */}
      <div
        className="w-full h-[87vw] md:h-full flex flex-col items-start justify-end  md:justify-center  md:pt-[5vw] md:pr-[10vw] px-[1.0667vw] md:px-0 relative z-10"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="text-white font-bold font-raleway md:text-[4.427vw] w-[80%] md:w-full text-[8.53vw] md:leading-[5.99vw] leading-[12vw]">
          {slides[currentSlide].text}
        </div>
        <div>
          <Link to={slides[currentSlide].buttonLink}>
            <Button
              title={"Know More"}
              customClasses={"md:mt-[3.125vw] mt-[5.333vw] mb-[5.333vw]"}
            />
          </Link>
        </div>

        <div className="hidden lg:block absolute bottom-32px-vw ml-4px-vw">
          <div className="flex items-center space-x-12px-vw">
            <div
              className="flex justify-center w-7 h-10 border-2 border-white rounded-full pt-vw-0.6 cursor-pointer"
              onClick={scrollToSection}
            >
              <span
                className={`w-2 h-2 rounded-full mt-2 bg-white animate-move cursor-pointer transition-transform duration-300 ${
                  isScrolledDown ? "translate-y-8" : "translate-y-0"
                }`}
              ></span>
            </div>
            <p className="text-white font-poppins font-regular text-[16px] tracking-wide">
              Scroll Down
            </p>
          </div>
        </div>
      </div>

      {/* Center Image Section */}
      <div className="absolute md:left-[57%]  left-14 bottom-0 transform -translate-x-1/2 z-10">
        <div className="md:w-[19.17vw] w-[40vw]">
          <img
            src={slides[currentSlide].image}
            alt="doctor_image"
            loading="lazy"
            className="w-full h-auto object-contain"
          />
        </div>
      </div>

      {/* Right section */}
      <div
        className="w-full h-[127.47vw] md:h-full flex items-end justify-end cursor-pointer pr-75px-vw pb-60px-vw  relative transition-all duration-500 ease-in-out "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div
          className="absolute top-0 left-0 w-full h-full  bg-cover bg-center bg-no-repeat transition-all duration-500 ease-in-out blur-sm hover:blur-none" 
          
          style={{
            backgroundImage: `url("/images/home/original_women.jpg")`,
          }}
        ></div>

        {/* Slider controls */}
        <div className="flex items-center z-30 blur-transparent bg-[rgba(255,255,255,0.1)] md:rounded-[3vw] rounded-[6vw] cursor-pointer border-solid border-[1px] border-[#FFFFFF80] px-18px-mvw md:px-38px-vw md:py-26px-vw py-13px-mvw md:space-x-32px-vw space-x-15px-mvw  backdrop-blur-[30px] drop-shadow-custom opacity-100">
          <div onClick={prevSlide} className="md:w-44px-vw w-20px-mvw">
            <img
              src="/icon/leftarrow.svg"
              alt="left arrow"
              className="w-full h-full object-cover"
            />
          </div>
          <div onClick={nextSlide} className="md:w-44px-vw w-20px-mvw">
            <img
              src="/icon/rightarrow.svg"
              alt="right arrow"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
