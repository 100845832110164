import Aos from "aos";
import React, { useEffect } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";

const LasikLaserSystem = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full relative">
      <CategoryTopSection
        breadcrumb={
          "Home | Products | Cornea & Refractive | Lasik Laser System"
        }
        centerHeading={"Lasik Laser System"}
      />

      <div className=" md:px-[7.29vw] md:mt-100px-vw md:mb-40px-vw mt-60px-mvw mb-20px-mvw  px-16px-mvw overflow-hidden">
        <img
          src={"/images/refractive/logo.png"}
          alt="logo.png"
          className="md:w-[10vw] w-[30vw] h-full object-contain block"
        />
      </div>

      <section
        className="grid md:grid-cols-2 gap-x-[8vw] font-poppins md:px-[7.29vw]  px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex gap-y-20px-mvw  md:gap-y-40px-vw items-start flex-col justify-start ">
          <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw  md:max-w-[80%] md:pt-0">
            Wavelight Refractive Suite EX 500 + FS 200
          </div>
          <div className="font-poppins h-[3vw] font-regular md:text-20px-vw  text-16px-mvw md:pt-0 opacity-80 text-[#2B2A29] md:max-w-[80%]">
          Achieve outstanding visual outcomes with our integrated Wavelight Technology
          </div>
          <div className="flex mt-40px-mvw md:mt-0 items-start justify-start">
            <img src="/images/plp/cornea/lasik1.png" alt="refractive" />
          </div>
        </div>
        <div className=" flex gap-y-20px-mvw  md:gap-y-40px-vw items-start flex-col justify-start">
          <div className="font-semibold  md:text-48px-vw text-24px-mvw md:max-w-[80%]">
            Wavelight Topolyzer Vario
          </div>
          <div className="font-poppins h-[3vw] font-regular md:text-20px-vw opacity-80 text-16px-mvw text-[#2B2A29] md:max-w-[80%]">
          Precision measurements for personalized treatment
          </div>
          <div className="mt-40px-mvw md:mt-0 w-full h-full">
            <img
              src="/images/plp/cornea/lasik2.png"
              alt="refractive"
              className="w-auto md:max-h-full"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LasikLaserSystem;
