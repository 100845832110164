import { RxHamburgerMenu } from "react-icons/rx";
import { CgSearch } from "react-icons/cg";
import { FiSearch } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navlinks, ourProductsSublinks } from "../../Data/Navliks";
import { CgChevronDown, CgClose } from "react-icons/cg";
import MobileMenu from "./MobileMenu";
import Home from "../../Pages/home/Home";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [productsHover, setProductsHover] = useState(false);
  const [hoveredSublink, setHoveredSublink] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false); // State for products dropdown
  const location = useLocation();
  const blackTextUrls = ["/search"];

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const threshold = window.innerHeight * 0.8;

      if (scrollY > threshold) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`${
          isSticky
            ? "fixed left-0  right-0"
            : "absolute left-0 md:min-h-[6.25vw] right-0"
        } z-30 md:mx-auto md:top-40px-vw md:w-11/12 w-full rounded-lg transition-all duration-300 ${
          isSticky
            ? "bg-white bg-opacity-5 text-black backdrop-blur-xl shadow-lg"
            : "bg-white/10 text-white backdrop-blur-26"
        } !w-[92%] mx-auto md:w-11/12 top-12px-mvw  `}
      >
        <nav className="w-full  flex justify-between items-center   py-16px-mvw  px-[2.8vw] md:py-8px-vw">
          {/* logo */}
          <Link to="/">
            <div className="flex flex-col justify-center items-center gap-y-[0.5vh] p-4px-vw">
              {blackTextUrls.includes(location.pathname) || isSticky ? (
                <div>
                  <div className="md:w-[8vw] w-[20.93vw]">
                    <img
                      src="/logo/sticky_logo.svg"
                      alt="footer_logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-y-[0.5vh] items-center text-center">
                  <div className="md:w-[4.69vw] w-[8vw]">
                    <img
                      src="/logo/logo_top.png"
                      alt="logo"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                  <div className="md:w-[10.16vw] w-[20vw] text-black">
                    <img
                      src="/logo/logo_middle.png"
                      alt="logo"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                  <div className="md:w-[10.16vw] w-[20vw]">
                    <img
                      src="/logo/logo_bottom.png"
                      alt="logo"
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
            </div>
          </Link>

          <div className="md:hidden  flex items-center space-x-4">
            {/* Search Icon */}
            <Link to="/search">
              <CgSearch
                className={`${
                  blackTextUrls.includes(location.pathname) || isSticky
                    ? "text-black"
                    : "text-white"
                } w-[7vw] h-[7vw]`}
                onClick={() => {
                  setMenuOpen(false);
                }}
              />
            </Link>

            {/* Hamburger Icon */}
            <button
              onClick={() => {
                setMenuOpen(!menuOpen);
                if (menuOpen) {
                  setProductsMenuOpen(false);
                }
              }}
            >
              {menuOpen ? (
                <CgClose
                  className={`${
                    blackTextUrls.includes(location.pathname) || isSticky
                      ? "text-black"
                      : "text-white"
                  } w-[8vw] h-[8vw]`}
                />
              ) : (
                <RxHamburgerMenu
                  className={`${
                    blackTextUrls.includes(location.pathname) || isSticky
                      ? "text-black"
                      : "text-white"
                  } w-[8vw] h-[8vw]`}
                />
              )}
            </button>
          </div>

          {/* navlinks for desktop */}
          <div className="hidden md:flex items-center whitespace-nowrap font-poppins font-medium uppercase text-[0.9375vw] space-x-[3.385vw]">
            {navlinks.map((item) => (
              <div
                key={item.name}
                className="relative flex items-center"
                onMouseEnter={() =>
                  item.name === "Our Products" && setProductsHover(true)
                }
                onMouseLeave={() =>
                  item.name === "Our Products" && setProductsHover(false)
                }
              >
                <Link to={item.link} className="flex items-center">
                  <ul
                    className={`${
                      blackTextUrls.includes(location.pathname) || isSticky
                        ? "text-black"
                        : "text-white"
                    } text-18px-vw tracking-wide`}
                  >
                    {item.name}
                  </ul>
                  {item.name === "Our Products" && (
                    <span className="ml-1">
                      <CgChevronDown
                        className={`w-[5vw] h-[5vw] md:w-[1.5vw] md:h-[1.5vw] ${
                          blackTextUrls.includes(location.pathname)
                            ? "text-black"
                            : ""
                        }`}
                      />
                    </span>
                  )}
                </Link>

                {/* Dropdown for Our Products */}
                {item.name === "Our Products" && (
                  <div
                    className={`absolute top-full left-0 mt-46px-vw bg-white text-[#2B2A29] font-poppins font-medium text-18px-vw opacity-100  rounded-bl-lg rounded-br-lg w-[20vw] py-[1.5vh] transform transition-all duration-300 ease-in-out ${
                      productsHover
                        ? "opacity-100 translate-y-0 visible"
                        : "opacity-0 invisible -translate-y-4"
                    }`}
                    onMouseEnter={() => setProductsHover(true)}
                    onMouseLeave={() => setProductsHover(false)}
                  >
                    {ourProductsSublinks.map((sublink) => (
                      <Link
                        key={sublink.id}
                        to={sublink.link}
                        className="flex justify-between items-center px-24px-vw py-[0.5vw] "
                        onMouseEnter={() => setHoveredSublink(sublink.id)}
                        onMouseLeave={() => setHoveredSublink(null)}
                      >
                        <span>{sublink.name}</span>
                        {hoveredSublink === sublink.id && (
                          <img
                            className="w-[4vw] h-[4vw] md:w-[1.2vw] md:h-[1.2vw]"
                            src="/icon/sublink_icon.svg"
                            alt="sublinkicon"
                            loading="lazy"
                          />
                        )}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}

            {/* <div className="relative w-[21.875vw] h-full flex items-center">
              <input
                className={`w-full pl-52px-vw py-13px-vh h-[5vh]  tracking-wider opacity-100 font-gotham  font-book rounded-full bg-transparent brightness-105 ${
                  isSticky
                    ? "border-black text-black placeholder-black  border-solid border-[0.5px]"
                    : "border-white text-white placeholder-white  border-solid border-[0.5px]"
                } outline-none   transition-all duration-300 ease-in-out text-16px-vw`}
                type="text"
                name="search"
                placeholder="Search..."
              />
              <div className="absolute left-22px-vw top-1/2 transform -translate-y-1/2">
                <div
                  className={`${
                    isSticky ? "text-black" : "text-white"
                  } w-20px-vw`}
                >
                  <FiSearch className="w-full h-full object-cover" />
                </div>
              </div>
            </div> */}

            <div className="flex items-center space-x-4">
              {/* Search Icon */}
              <Link to="/search">
                <CgSearch
                  className={`${
                    blackTextUrls.includes(location.pathname) || isSticky
                      ? "text-black"
                      : "text-white"
                  } w-[1.25vw] h-[1.25vw]`}
                />
              </Link>
            </div>
          </div>
        </nav>
      </header>

      {
        <MobileMenu
          menuOpen={menuOpen}
          productsMenuOpen={productsMenuOpen}
          setProductsMenuOpen={() => setProductsMenuOpen(!productsMenuOpen)}
          setMenuOpen={setMenuOpen}
        />
      }
    </>
  );
};

export default Header;
