import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast"; // Import Toaster
import CategoryTopSection from "../../components/category/CategoryTopSection";
import SectionHeading from "../../components/common/SectionHeading";
import Button from "../../components/common/Button";
import Aos from "aos";
import { AiOutlineClose, AiOutlineUpload } from "react-icons/ai";
import Joi from "joi";
import helper from "../../helper/helper";
import config from "../../config/config";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    resume: null,
    message: "",
  });
  const fileInputRef = useRef();
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const baseUrl = config.api_url;

  const handleRemoveFile = () => {
    fileInputRef.current.value = null;
    handleFileChange({ target: { name: "resume", files: [] } });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validationResult = schema.extract(name).validate(value);
    if (validationResult.error) {
      setErrors({
        ...errors,
        [name]: validationResult.error.details[0].message,
      });
    } else {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const checkFileExtension = (fileName) => {
      const allowedExtensions = ["jpg", "jpeg", "doc", "docx", "pdf"];
      const extension = fileName.split(".").pop().toLowerCase();
      return allowedExtensions.includes(extension);
    };
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setErrors({ ...errors, resume: "File size should not exceed 10MB." });
      }
      if (!checkFileExtension(file.name)) {
        setErrors({
          ...errors,
          resume: "Only JPG, JPEG, DOC, DOCX and PDF files are allowed.",
        });
      } else {
        setFormData({ ...formData, resume: file });
        setErrors({ ...errors, resume: null });
      }
    } else {
      setFormData({ ...formData, resume: null });
    }
  };

  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().messages({
      "string.empty": "Full name is required.",
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Email is required.",
        "string.email": "Please enter a valid email address.",
      }),
    phone: Joi.string()
      .pattern(/^[0-9]{10}$/)
      .required()
      .messages({
        "string.empty": "Phone number is required.",
        "string.pattern.base": "Phone number must be 10 digits",
        "any.required": "Phone number is required",
      }),
    resume: Joi.object().required().messages({
      "any.required": "Please upload your resume.",
      "object.base": "Please upload your resume.",
    }),
    message: Joi.string().allow(""),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = schema.validate(formData, { abortEarly: false });

    if (validationResult.error) {
      const newErrors = {};
      validationResult.error.details.forEach((detail) => {
        newErrors[detail.path[0]] = detail.message;
      });
      setErrors(newErrors);
      setIsSubmitted(false);
      const firstErrorField = validationResult.error.details[0].path[0];
      if (firstErrorField) {
        helper.scroll(`${firstErrorField}-field`);
      }
      return;
    }
    const careersBody = new FormData();
    for (let key in formData) {
      careersBody.append(key, formData[key]);
    }

    const response = await helper.post(`${baseUrl}/career-leads`, careersBody);

    if (response?.data) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        resume: null,
        message: "",
      });
      setErrors({});
      helper.scroll(`success-field`);
    }
    setIsSubmitted(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | Careers "}
        centerHeading={"Careers"}
      />

      <div
        className="grid  grid-cols-1 text-[#2B2A29] md:px-[7.29vw] px-16px-mvw py-60px-mvw md:py-[6.25vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="text-center md:space-y-40px-vw space-y-20px-mvw block md:mb-60px-vw">
          <SectionHeading title={" APPLY NOW"} />
          <p className="text-20px-mvw  md:text-28px-vw">
            We continuously look for good talent. Keep us updated with your
            profiles.
          </p>
        </div>
        <div className="w-full md:flex items-center justify-center md:pl-[6.46vw] pt-40px-mvw md:pt-0">
          <form onSubmit={handleSubmit} className="w-full md:max-w-[55vw]">
            <div className="flex w-full flex-col gap-y-30px-mvw md:gap-y-60px-vw text-[#2B2A29] items-start justify-start">
              <div id="name-field" className="w-full">
                <label
                  htmlFor="name"
                  className="font-poppins text-[#2B2A29] font-medium md:text-28px-vw text-18px-mvw"
                >
                  Full Name*
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your full name"
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

              <div id="email-field" className="w-full">
                <label
                  htmlFor="email"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your email Id"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              <div id="phone-field" className="w-full">
                <label
                  htmlFor="phone"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Phone Number*
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your phone number"
                />
                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              </div>

              <div id="resume-field" className="w-full">
                <label
                  htmlFor="resume"
                  className="font-poppins text-[#2B2A29] font-medium md:text-28px-vw text-18px-mvw"
                >
                  Upload Resume*
                </label>

                <label
                  htmlFor="resume"
                  className="relative  border-b-[1.5px] border-[#2B2A29] border-opacity-20 rounded-none bg-transparent w-full py-[3vw] px-[3vw] md:py-[1.2vw] md:px-[1vw] block cursor-pointer"
                >
                  <input
                    type="file"
                    name="resume"
                    id="resume"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  {formData.resume ? (
                    <div className="flex items-center justify-between">
                      <p className="mt-[0.5vw] text-[#343B3C] text-[3vw] md:text-[1.2vw]">
                        Selected file: {formData.resume.name}
                      </p>
                      <AiOutlineClose
                        onClick={handleRemoveFile}
                        className="text-[#343B3C] text-[5vw]  md:text-[1.5vw] cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="md:my-[1vw]">
                      <img
                        src="/icon/uploadicon.svg"
                        alt="Upload Resume"
                        className="absolute right-[2vw] md:top-[20%] -top-[30%] transform -translate-y-1
                    /2 text-[#343B3C] text-[5vw] md:text-[2.2vw]"
                      />
                    </div>
                  )}
                </label>

                {errors.resume && (
                  <p className="text-red-500">{errors.resume}</p>
                )}
              </div>
              <div id="message-field" className="w-full">
                <label
                  htmlFor="message"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter your message"
                />
              </div>

              <div className="w-full mt-20px-mvw md:mt-0 text-center md:text-left ">
                <Button title={"Submit"} />
              </div>
              <div id="success-field">
                {isSubmitted && (
                  <p className="font-poppins text-green-700 italic mt-2 text-lg">
                    Thank you for your interest! We'll get back to you soon.
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Career;
