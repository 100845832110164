import React from 'react'
import CategoryTopSection from '../../components/category/CategoryTopSection'
import Listing from '../../components/category/Listing'


const RetinaGlaucoma = () => { 
  const listingData = [
    {
      id: "01",
      name: "OCT",
      img: "/images/plp/rectina/1.png", 
      link:"/products/retina-glaucoma/oct"
    },
    {
      id: "02",
      name: "Perimeters",
      img: "/images/plp/rectina/2.png",
      link:"/products/retina-glaucoma/perimeters",
    },
    {
      id: "03",
      name: "Tonometer",
      img: "/images/plp/rectina/3.png", 
      link:"/products/retina-glaucoma/tonometer",
    },
    {
      id: "04",
      name: "Pachymeter",
      img: "/images/plp/rectina/4.png", 
      link:"/products/retina-glaucoma/pachymeter",
    },
    {
      id: "05",
      name: "ERG",
      img: "/images/plp/rectina/5.png", 
      link:"/products/retina-glaucoma/erg",
    },
    {
      id: "06",
      name: "B-Scan",
      img: "/images/plp/rectina/6.png", 
      link:"/products/retina-glaucoma/b-scan",
    },
    {
      id: "07",
      name: "Trocars & Infusion Cannulas",
      img: "/images/plp/rectina/7.png", 
      link:"/products/retina-glaucoma/mani-trocars-and-infusion-cannulas",
    },
  ];
  return (
    <section className='w-full relative'>
       <CategoryTopSection breadcrumb={"Home | Products | Retina & Glaucoma"} centerHeading={"Retina & Glaucoma"}/>
       <Listing heading={"RETINA & GLAUCOMA PRODUCTS"} data={listingData}/>
    </section>
  )
}

export default RetinaGlaucoma
