import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { topographer } from "../../../Data/CorneaRefractive";
import BottomSlider from "../../slider/BottomSlider";
import CategorySelector from "../../category/CategorySelector";
import Popup from "../../common/Popup";
import { useProductFromSlug } from "../../../hooks/useIndexFromUrl";

const Topographer = () => {
  const [activeIndex, setActiveIndex] = useProductFromSlug(topographer, 0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
    setProductData({
      category: "Cornea & Refractive",
      subCategory: "Topographer",
      product: item.model,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const activeCategory = topographer[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Topographer"}
        centerHeading={"Topographer"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw  md:px-[7.29vw] "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={topographer}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 pt-30px-mvw px-16px-mvw md:px-0  gap-y-40px-mvw md:gap-y-0 grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw md:gap-y-30px-vw">
                <div className="md:max-w-full md:max-h-[2vw] max-h-[3.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>
                <div
                  className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0"
                  dangerouslySetInnerHTML={{ __html: item.model }}
                ></div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-start  gap-x-12px-mvw md:gap-x-20px-vw"
                  >
                    <div className="bg-[#519A7F] md:mt-[0.6vw] mt-10px-mvw rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex w-full h-full items-start justify-center ">
                {item.img.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt=""
                    className="w-auto  max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full px-16px-mvw md:px-0  gap-x-16px-vw pt-60px-mvw  md:pt-[3vw] pb-80px-mvw md:pb-0 mx-auto md:flex-row flex-col gap-y-16px-mvw  md:gap-y-0 flex items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                  productData={{
                    category: "Cornea & Refractive",
                    subCategory: "Topographer",
                    product: item.model,
                  }}
                />
              </div>
            }
            {/* {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] ">
                 
                 
                  <div className="absolute left-0 md:w-full md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                

                  <BottomSlider slides={item.videoData} />
                </div>
              ))} */}
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default Topographer;
