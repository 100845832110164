import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SectionHeading from "./SectionHeading";
import Button from "./Button";
import Joi from "joi";
import helper from "../../helper/helper";
import config from "../../config/config";

const Popup = ({ showModal, selectedPdf, handleCloseModal, data }) => {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const baseUrl = config.api_url;
  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().messages({
      "string.empty": "Name is required",
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "Email is required",
        "string.email": "Please enter a valid email address",
      }),
    phone: Joi.string()
      .pattern(/^[0-9]{10}$/)
      .required()
      .messages({
        "string.empty": "Phone number is required",
        "string.pattern.base": "Phone number must be 10 digits",
        "any.required": "Phone number is required",
      }),
  });

  const validateForm = () => {
    const validationResult = schema.validate(formData, { abortEarly: false });

    if (validationResult.error) {
      const newErrors = {};
      validationResult.error.details.forEach((detail) => {
        newErrors[detail.path[0]] = detail.message;
      });
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const leadData = {
        ...formData,
        category: data?.category,
        sub_category: data?.subCategory,
        product_name: data?.product,
      };

      const response = await helper.post(`${baseUrl}/product-leads`, leadData);
      if (response?.data) {
        localStorage.setItem("userSubmittedForm", true);
        localStorage.setItem("userFormData", JSON.stringify(formData));
        setIsSubmitted(true);
        setFormData({ name: "", email: "", phone: "" });
        if (selectedPdf) {
          setSuccessMessage(
            "Thank you for your interest! The brochure will download in a moment..."
          );
          setTimeout(() => {
            const link = document.createElement("a");
            link.href = selectedPdf;
            link.download = selectedPdf.split("/").pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => {
              handleCloseModal();
            }, 1000);
          }, 2000);
        } else {
          setSuccessMessage("Thank you for your interest!");
          setTimeout(() => {
            handleCloseModal();
          }, 3000);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    const validationResult = schema.extract(name).validate(value);
    if (validationResult.error) {
      setErrors({
        ...errors,
        [name]: validationResult.error.details[0].message,
      });
    } else {
      setErrors({ ...errors, [name]: null });
    }
  };

  // useEffect(() => {
  //   if (showModal) {
  //     document.documentElement.style.overflow = "hidden";
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.documentElement.style.overflow = "";
  //     document.body.style.overflow = "";
  //   }

  //   return () => {
  //     document.documentElement.style.overflow = "";
  //     document.body.style.overflow = "";
  //   };
  // }, [showModal]);

  if (!showModal) return null;

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full h-full flex items-center justify-center bg-[#2b2a29] bg-opacity-50">
      <div
        className=" p-8 rounded-lg md:w-[30vw] w-[90%]  shadow-lg relative"
        style={{
          backgroundImage: `url("/images/home/overall_bg.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // minHeight: "100vh",
        }}
      >
        {/* Close Icon */}
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-[#2B2A29] text-28px-mvw md:text-32px-vw hover:text-red-500 transition-all"
        >
          <AiOutlineClose />
        </button>

        <div className="justify-self-start block md:mb-20px-vw mb-20px-mvw">
          <SectionHeading title={"Enter Your Details"} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="md:space-y-30px-vw space-y-20px-mvw"
        >
          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Name*
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Name"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>

          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Email*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Email"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>

          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Phone Number*
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Phone Number"
            />
            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
          </div>

          <div className="text-left w-full md:mt-20px-vw">
            <Button
              title="Submit & Download"
              type="submit"
              className="text-white px-4 py-2 rounded"
            />
          </div>
          {isSubmitted && (
            <p className="font-poppins text-green-600 text-center italic mt-4">
              {successMessage}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Popup;
