export const UltrasoundAScanData = [
  {
    id: 1,
    model: "AL-4000",
    slug: "al-4000",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "AL-4000",
        name: "This extremely handy and easy-to-use combination of Bio and pachymeter leaves nothing to be desired in terms of comfort and flexibility.",
        main_points: [
          "Extremely handy & compact",
          "Easy to use",
          "Multiple IOL power calculation",
          "Contact & Immersion mode",
          "Bluetooth connectivity",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/AL-4000.pdf",
          },
        ],
        img: ["/images/cataract/al-4000@2x.png"],
        video: [
          {
            videoSrc: "/images/cataract/video_1.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Scanmate A",
    slug: "scanmate-a",
    data: [
      {
        id: 1,
        logo: "/images/cataract/DGH.png",
        model: "Scanmate A DGH 600",
        name: "DGH 6000 A-Scan (Scanmate A) offers unparalleled usability and accuracy, with real-time waveform analysis and guidance features for optimal measurements.",
        main_points: [
          "Ultra portable",
          "Unique measurement guidance",
          "Unique grading algorithm",
          "Contact & immersion mode",
          "Compression lock-out",
          "Multiple IOL power calculation",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
        ],
        img: ["/images/cataract/scanmate-a@2x.png"],
        video: [
          {
            videoSrc: "/images/cataract/scanmatethumb.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
          },
        ],
      },
    ],
  },
];

export const opticalBiometerData = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "OA 2000",
    slug: "oa_2000",
    name: "OA-2000 is a Swept Source Optical Biometer with Topography  for measuring axial length, the corneal curvature radius, corneal topography, and more in a single shot with high speed scans.",
    main_points: [
      "Fully automatic",
      "Eye tracking system",
      "Fast & accurate",
      "Wide & touch screen",
      "Modern IOL power calculations",
      "Barrette Site",
      "Ray Tracing (Optional)",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/OA-2000_Brouchure- new.pdf",
      },
    ],
    img: ["/images/cataract/oae@2x.png"],
    imgGallery: [
      "/images/cataract/qa1.png",
      // "/images/cataract/OA2000_02.png",
      "/images/cataract/qa2.png",
      "/images/cataract/qa3.png",
      // "/images/cataract/OA 2000.png",
    ],
    // videoData: [
    //   {
    //     videothumbnail: "/images/cataract/overview.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Cameraaaa",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //   {
    //     videothumbnail: "/images/cataract/video_2.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //   {
    //     videothumbnail: "/images/cataract/overview.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    //    {
    //     videothumbnail: "/images/cataract/video_2.png",
    //     videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
    //     videoTitle: "Non-Myd Color Fundus Camera",
    //     videoDesc:
    //       "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
    //   },
    // ],
    video: [
      {
        videothumbnail: "/images/cataract/video_2.png",
        videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
        videoTitle: "Product Description",
        videoDesc:
          "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      },
      // {
      //   videothumbnail: "/images/cataract/OA-2000.png",
      //   videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
      //   videoTitle: "User Guide",
      //   videoDesc:
      //     "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      // },
      // {
      //   videothumbnail: "/images/cataract/OA-2000.png",
      //   videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
      //   videoTitle: "Technical Overview",
      //   videoDesc:
      //     "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
      // },
      // Add more videos as needed
    ],
  },
];

export const wavefront = [
  {
    id: 1,
    logo: "/images/rectina/csologo.png",
    model: "OSIRIS-T Aberrometer + Topographer ",
    slug: "osiris_t",
    name: "The Osiris-T is a total ocular aberrometer  and corneal topographer, indispensable for the correct evaluation of patients who have, in addition to traditional low-order defects, even more complex ocular aberrations, either corneal or internal.",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        data: "/pdf/brochures/Osiris_T_ENG.pdf",
      },
    ],
    img: ["/images/cataract/osiris-t-1@2x.png"],
    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Overview Screen",
      },
    ],
  },
];

export const selectiveLaser = [
  {
    id: 1,
    logo: "/images/cataract/capsu.png",
    // model: "CAPSULASER",
    slug: "capsulaser",
    name: "CAPSULaser is a selective laser technology that is used create an accurately sized, perfectly centred, tear resistant capsulotomy during Cataract surgery",
    main_points: [
      "Laser precision Capsulotomy in size & circularity",
      "Free floating Capsulotomy",
      "360° IOL coverage",
      "Capsulotomy size from 4.0 to 5.5 mm",
      "Rolled over edge",
      "Elastic and tear resistance capsule",
      "Seamless integration in to Cataract work flow",
    ],
    keyFeatureObject: {
      commonImg: "/images/cataract/capsulaser-panel.png",
      key_feature: [
        {
          id: 1,
          title: "PRECISION AND ACCURACY",
          img: "/images/cataract/Group 9734.svg", // using common image
          desc: "In 0.3 seconds LACS delivers unparalleled accuracy and control, surgically engineering a precisely centred circular foundation of your new lens",
        },
        {
          id: 2,
          title: "ADVANCED SAFETY FEATURES",
          img: "/images/cataract/Group 9730.svg",
          desc: "Advanced Laser control significantly enhances safety by creating a precise foundation for your new lens, ensuring increased accuracy throughout the procedure",
        },
        {
          id: 3,
          title: "FASTER RECOVERY",
          img: "/images/cataract/Group 9738.svg",
          desc: "LACS enables faster visual recovery by employing contactless and precise laser delivery, minimizing tissue irritation.",
        },
        // {
        //   id: 4,
        //   title: "CUSTOM SCAN PROTOCOLS",
        //   img: "/images/rectina/greendott.svg",
        //   desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
        // },
        {
          id: 5,
          title: "CONSISTENCY",
          img: "/images/cataract/Group 9743.svg",
          desc: "LACS ensures consistency in every procedure, delivering predictable and optimal visual outcomes which result in high patient satisfaction.",
        },
        {
          id: 6,
          title: "RELIABLE",
          img: "/images/cataract/Group 9732.svg",
          desc: "LACS is the reliable choice, offering consistent and repeatable results. The Advanced Selective Laser technology has been proven to reduce complications and promote faster healling.",
        },
        {
          id: 7,
          title: "EFFICIENT & STREAMLINED WORKFLOW",
          img: "/images/cataract/Group 9736.svg",
          desc: "Capsulaser integrates seamlessly into existing surgical workflows, minimizing disruptions & increasing efficiency.",
        },
        // {
        //   id: 8,
        //   title: "CONNECTIVITY",
        //   img: "/images/rectina/img7.svg",
        //   desc: "A proficient networking solution with DICOM and EMR capabilities. Quickly and easily export to a desired location.",
        // },
      ],
    },
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        // data: "/pdf/brochures/capsulaser-brochure-hr.pdf",
      },
    ],
    img: ["/images/cataract/capsulaser-front-panel.png"],
    advantage: [
      {
        id: 1,
        name: "THE CAPSULASER ADVANTAGE",
        points: [
          {
            id: 1,
            img: "/images/cataract/adv_5.svg",
            desc: "Consistency Capsulotomies every surgery",
          },
          {
            id: 2,
            img: "/images/cataract/adv_4.svg",
            desc: "Laser precision for size and centration",
          },
          {
            id: 3,
            img: "/images/cataract/adv_3.svg",
            desc: "An elastic capsulotomy rim resistent to tearing",
          },
          {
            id: 4,
            img: "/images/cataract/adv_2.svg",
            desc: "A small ergonomic microscope mounted laser device",
          },
          {
            id: 5,
            img: "/images/cataract/adv_1.svg",
            desc: "CAPSULaser provides a cost and time effective solution",
          },
        ],
      },
    ],

    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/selectivethumb.png",
        videoUrl: "https://youtu.be/CuPHd0V9cgI",
        videoTitle: "Surgical Video",
      },
    ],
  },
];

export const surgical = [
  {
    id: 1,
    logo: "/images/cataract/mani.png",
    model: "Mani Ophthalmic Knife",
    slug: "mani_ophthalmic_knife",
    name: "Ideal for making incisions on cornea and sclera during eye surgery with cross-lapped edge technique",
    main_points: [
      "Ultimate sharpness",
      "Less induced Astigmatism",
      "Less tissue damage",
      "Faster patient recovery",
    ],
    additionalInfo: [
      {
        id: 2,
        title: "Brochures",
        // data: "/pdf/brochures/mani.pdf",
      },
    ],
    img: ["/images/cataract/surgical-blades@2x.png"],
    video: [
      {
        videoSrc: "/images/cataract/surgical_video.png",
        videoUrl: "https://youtu.be/CuPHd0V9cgI",
      },
    ],
  },
];

export const OtTableData = [
  {
    id: 1,
    model: "500 XLE",
    slug: "500-xle",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "500 XLE",
        name: "500 XLE operating chair is designed for ergonomic and comfortable patient positioning and treatment, particularly for outpatient surgical techniques.",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Intuitive manual keypad",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
        ],
        img: ["/images/cataract/500-XLE.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/500-XLE(1).png",
              "/images/cataract/500_XLE_POI_1.png",
            ],
            videoData: [
              {
                videothumbnail: "/images/cataract/500_XLE_POI_2-1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/cataract/500_XLE_POI_einspaltig.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
              {
                videothumbnail: "/images/cataract/500_XLE_Slider_2_3-1.png",
                videoSrc: "https://www.youtube.com/embed/CuPHd0V9cgI",
              },
            ],
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "SurgiLine",
    slug: "surgiline",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "SurgiLine",
        name: "The stable and ergonomic surgeon's chair surgiLine for precise and back-friendly work in microsurgery",
        main_points: [
          "Individually adaptable to the surgeon",
          "Comfort and foamed seat",
          "Unrestricted mobility",
          "Intuitive foot padel for height adjustment",
          "All around movable arm rest",
          "Emergency stop button for safe & smooth operation",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
        ],
        img: ["/images/cataract/SurgiLine.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/SurgiLine(1).png",
              "/images/cataract/SurgiLine_POI_2.png",
              "/images/cataract/SurgiLine_POI_1.png",
            ],
            images: [
              "/images/cataract/OTtablechair_1.png",
              "/images/cataract/OTtablechair_2.png",
            ],
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
];
const dhakad = {
  name: "Deepak",
};
