import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import BottomSlider from "../../slider/BottomSlider";
import { pachymeter } from "../../../Data/CorneaRefractive";
import CategorySelector from "../../category/CategorySelector";
import ImageSlider from "../../slider/ImageSlider";
import Popup from "../../common/Popup";
import { useProductFromSlug } from "../../../hooks/useIndexFromUrl";

const PachymeterCornea = () => {
  const [activeIndex, setActiveIndex] = useProductFromSlug(pachymeter, 0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
    setProductData({
      category: "Cornea & Refractive",
      subCategory: "Pachymeter",
      product: item.model,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const activeCategory = pachymeter[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Pachymeter"}
        centerHeading={"Pachymeter"}
      />

      <section
        className="w-full  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={pachymeter}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  grid-cols-1 md:px-0 gap-y-40px-mvw md:pt-40px-vw pt-24px-mvw md:gap-y-[2vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[2vw] max-h-[3.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className="flex items-end justify-end ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-h-[38.4vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <>
                <div className="w-full  gap-x-16px-vw md:pt-[3vw]  md:px-[14vw] mx-auto md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 py-60px-mvw md:py-0 flex items-center justify-center">
                  <DownloadCard
                    data={item.additionalInfo}
                    onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                    productData={{
                      category: "Cornea & Refractive",
                      subCategory: "Pachymeter",
                      product: item.model,
                    }}
                  />
                </div>

                {item?.video &&
                  item?.video.map((item) => (
                    <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                      <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                        PRODUCT GALLERY
                      </h1>

                      {/* Image Gallery Section */}
                      {/* <div className="md:mb-100px-vw"> */}

                      <div className="hidden md:mt-80px-vw  md:flex items-center justify-center">
                        {item.imggallery.map((imgSrc, index) => (
                          <img
                            key={index}
                            src={imgSrc}
                            alt={`imgGallery-${index}`}
                            className="md:max-w-[24.23vw] md:max-h-[24.23vw]  object-cover"
                          />
                        ))}
                      </div>

                      {/* </div> */}
                      {/* Separator Line */}
                      {/* <div className="absolute left-0 md:w-[87%] md:mb-[7vw] md:mx-[7vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                      {/* Video and Image Navigation slider */}
                      {/* {item?.videoData.length > 2 ? (
                        <BottomSlider slides={item.videoData} />
                      ) : (
                        <div
                          className="flex md:flex-row flex-col  items-start  md:pt-40px-vw
                      justify-start gap-x-80px-vw"
                        >
                          {item?.videoData.map((videoItem, index) => (
                            <div
                              key={index}
                              className="mb-8 md:mt-60px-vw flex flex-col items-start"
                            >
                              <img
                                src={videoItem?.videothumbnail}
                                alt={`video-${index}`} 
                                className="md:max-w-[42.24vw] block"
                              />
                              <div className="md:mt-15px-vw mt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                                {videoItem?.title}
                              </div>
                            </div>
                          ))}
                        </div>
                      )} */}
                    </div>
                  ))}
              </>
            }
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default PachymeterCornea;
