import React from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import Listing from "../../category/Listing";

const Bscan = () => {
  const listingData = [
    // {
    //   id: "01",
    //   name: "Scanmate Flex",
    //   logo: "/images/rectina/DGH.png",
    //   img: "/images/plp/cornea/ubm1.png",
    //   link: "/products/retina-glaucoma/scanmate-flex",
    // },
    {
      id: "01",
      name: "UD-800",
      logo: "/images/rectina/TOMEY.png",
      img: "/images/plp/rectina/6.png",
      link: "/products/retina-glaucoma/ud-800",
    },
    {
      id: "02",
      name: "Scanmate Flex",
      logo: "/images/rectina/DGH.png",
      img: "/images/plp/cornea/ubm1.png",
      link: "/products/retina-glaucoma/scanmate-flex",
    },
  ];
  return (
    <section className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | B-Scan"}
        centerHeading={"B-Scan"}
      />
      <Listing data={listingData} />
    </section>
  );
};

export default Bscan;
