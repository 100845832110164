export const steamSterilizers = [
  {
    id: 1,
    model: "HS-60SL",
    slug: "hs-60SL",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-60SL",
        name: "Vertical type Steam Sterilizer (60 Liters)",
        main_points: [
          "Ergonomic design",
          "Automatic door system",
          " LCD monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/HS-60_SL.pdf",
          },
        ],
        img: ["/images/sterilization/HS-60SL.png"],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "HS-2522BL",
    slug: "hs-2522BL",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-2522BL",
        name: "B-Class Steam Sterilizer (22 Liters)",
        main_points: [
          "Ergonomic design",
          "Graphic LCD monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/Small_Sterilizer_BL_SD__B_CLASS.pdf",
          },
        ],
        img: ["/images/sterilization/HS-2522BL.png"],
        video: "",
      },
    ],
  },
  {
    id: 3,
    model: "HS-2522SD",
    slug: "hs-2522SD",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-2522SD",
        name: "S-Class Steam Sterilizer (22 Liters)",
        main_points: [
          "Ergonomic design",
          "LED display monitor",
          "Innovative air removal",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/Small_Sterilizer_BL_SD__B_CLASS.pdf",
          },
        ],
        img: ["/images/sterilization/HS-2522SD.png"],
        video: "",
      },
    ],
  },
  {
    id: 4,
    model: "HS-1607SD",
    slug: "hs-1607SD",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "HS-1607SD",
        name: "S-Class Steam Sterilizer (7 Liters)",
        main_points: [
          "Ergonomic design",
          "LED display monitor",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
          "Safe & durable unwelded chamber",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/Small_Sterilizer_BL_SD__B_CLASS.pdf",
          },
        ],
        img: ["/images/sterilization/HS-1607SD.png"],
        video: "",
      },
    ],
  },
  {
    id: 5,
    model: "ENBIO S",
    slug: "enbio-s",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "ENBIO S",
        name: "The Enbio S autoclave is the world's fastest Class B steam sterilizer",
        main_points: [
          "7-minute 134°C FAST program.",
          "Compact design",
          "Fast sterilization cycles",
          "2.7 liters Capacity",
          "Suitable for medical applications",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/Small_Sterilizer_BL_SD__B_CLASS.pdf",
          },
        ],
        img: ["/images/sterilization/ENBIO_S.png"],
        video: "",
      },
    ],
  },
  {
    id: 6,
    model: "ENBIO PRO",
    slug: "enbio-pro",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "ENBIO PRO",
        name: "The Enbio PRO autoclave is the world's fastest Class B steam sterilizer",
        main_points: [
          "10-minute 134°C FAST program.",
          "Compact design",
          "Quiet operation",
          "Fast sterilization cycles",
          "5.3 liters Capacity",
          "Rapid sterilization of loose or wrapped instrument",
          "Automatic report saving to USB stick",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            data: "/pdf/brochures/Small_Sterilizer_BL_SD__B_CLASS.pdf",
          },
        ],
        img: ["/images/sterilization/ENBIO_PRO.png"],
        video: "",
      },
    ],
  },
];

export const indicators = [
  {
    id: 1,
    logo: "/images/sterilization/propper.png",
    model: "VAPOR LINE Steam Sterilization Indicator",
    slug: "vapor_line_steam_sterilization_indicator",
    name: "VAPOR LINE® Steam Sterilization Integrators are reliable chemical indicators that monitor all critical steam sterilization parameters, making achieving safe and consistent sterilization results easy.",
    main_points: [
      "Class 5 Indicator",
      "Equivalent to biological indicator",
      "Works on time, temperature & pressure",
      "Gives 100% guarantee of sterilization",
      "Available with or without extender",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Brochures",
        // data: "/pdf/brochures/vapourline-2.pdf",
      },
    ],
    img: ["/images/sterilization/image002.png"],
    video: "",
  },
];

export const packagingmaterial = [
  {
    id: 1,
    model: "Steriking Pouches & Roll",
    slug: "steriking-pouchesroll",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/wipak.png",
        model: "Steriking Rolls & Pouches",
        name: "The Steriking® product range offers a wide variety of packaging types for all common sterilization processes, and optimum size for each item",
        main_points: [
          "Peel pouches & rolls",
          "Self-sealable peel pouches",
          "Strong seals & sealed edges",
          "Process Indicators",
          "Safe print design",
          "Strong & flexible multilayer films with medical grade paper",
          "Suitable for steam & gas sterilization",
          "Compliant with norms & standards",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            // data: "/pdf/brochures/wipak.pdf",
          },
        ],
        img: ["/images/sterilization/img_006.png"],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "Rotosealer",
    slug: "rotosealer",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/wipak.png",
        model: "Rotosealer",
        name: "ROTOSEALERS for quick and reliable closing of sterilisation packaging materials",
        main_points: [
          "All-day readiness for use",
          "Energy saving when the machine is not in use",
          "All sealing process parameters are controlled",
          "Adjustable temperature rate",
          "Provide strong & impermeable seals",
          "Manufactured according to European safety regulations",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            // data: "/pdf/brochures/wipak.pdf",
          },
        ],
        img: ["/images/sterilization/img_007.png"],
        img2: [""],
        video: "",
      },
    ],
  },
  {
    id: 3,
    model: "Impulse sealer IS250",
    slug: "impulse_sealer_is250",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/wipak.png",
        model: "Impulse sealer IS250",
        name: "IS250 impulse sealer is a efficient choice, featuring a built-in cutting device and electronic automatic sealing time system.",
        main_points: [
          "User-friendly, Economical",
          "Constant Sealing Pressure, No Warming-up Time",
          "Sealing Technique According To Din 58953, Part 7",
          "Electronic, Automatic Sealing Time System",
          "Acoustic Alarm And Signal Lamp For The Sealing Time",
          "Built-in Cutting Device With Rotating Circular Knife",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
            // data: "/pdf/brochures/wipak.pdf",
          },
        ],
        img: ["/images/sterilization/Impulse Sealer.png"],
        img2: [""],
        video: "",
      },
    ],
  },
];
