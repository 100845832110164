import React, { useEffect } from "react";
import ListingCards from "./ListingCards";
import Aos from "aos";

const Listing = ({ heading, data }) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    }); 
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className="w-full md:mt-[5.9vw] mt-60px-mvw"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <h1 className="text-center uppercase text-[#2B2A29] font-raleway font-bold md:text-32px-vw text-24px-mvw">
        {heading}
      </h1>
      <ListingCards data={data} />
    </section>
  );
};

export default Listing;
