import React, { useState } from "react";
import { HiChevronUp, HiChevronDown } from "react-icons/hi";
import { motion, AnimatePresence } from "framer-motion";

const CategorySelector = ({
  categories,
  onCategorySelect,
  activeCategoryIndex,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  console.log(activeCategoryIndex);
  return (
    <div className="flex items-center justify-center">
      {/* Desktop View */}
      <div className="rounded-[4vw] hidden backdrop-blur-[30px] bg-white md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10  gap-y-40px-vh md:gap-y-0 md:px-40px-vw md:py-20px-vw">
        {categories.map((category, index) => (
          <div
            key={category.id}
            className={`relative cursor-pointer ${
              activeCategoryIndex === index
                ? "opacity-100 font-bold   md:rounded-[3vw] rounded-[8vw] py-12px-vh"
                : "opacity-40 font-medium"
            }`}
            onClick={() => onCategorySelect(index)}
          >
            <div
              className={`relative ${
                activeCategoryIndex === index
                  ? "relative flex items-center justify-center px-32px-vw py-10px-vw text-28px-vw font-semibold text-[#2B2A29] rounded-[30px] bg-gradient-to-r from-[rgba(81,154,127,0.3)] via-[rgba(81,154,127,0.6)] to-[rgba(81,154,127,0.3)] backdrop-blur-[26px] shadow-[0px_-15px_40px_rgba(81,154,127,0.7),0px_10px_20px_rgba(0,0,0,0.15)] overflow-hidden "
                  : ""
              } flex items-center justify-center`}
            >
              <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.46vw] text-18px-mvw">
                {category.model}
              </span>
              <div className="absolute top-0 left-0 w-full h-full rounded-[inherit] bg-gradient-to-b from-transparent to-[rgba(255,255,255,0.25)] pointer-events-none"></div>
            </div>
          </div>
        ))}
      </div>

      <div className="md:hidden block">
        <div className="grid grid-cols-1 relative">
          <div
            className="  py-20px-mvw inline-flex justify-between  items-center rounded-[8vw] "
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="relative w-full flex items-center gap-x-100px-mvw justify-between md:px-32px-vw px-25px-mvw md:py-10px-vw py-20px-mvw md:text-28px-vw text-18px-mvw font-semibold text-[#2B2A29] rounded-[30px] bg-gradient-to-r from-[rgba(81,154,127,0.3)] via-[rgba(81,154,127,0.6)] to-[rgba(81,154,127,0.3)] backdrop-blur-[26px] shadow-[0px_-15px_40px_rgba(81,154,127,0.7),0px_10px_20px_rgba(0,0,0,0.15)] overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full rounded-[inherit] bg-gradient-to-b from-transparent to-[rgba(255,255,255,0.25)] pointer-events-none"></div>
              <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                <span>
                  {categories[activeCategoryIndex]?.model || "Select Category"}
                </span>
              </span>
              <div className="text-18px-mvw md:mr-40px-mvw text-[#2B2A29]">
                {isExpanded ? <HiChevronUp /> : <HiChevronDown />}
              </div>
            </div>
          </div>

          <AnimatePresence>
            {isExpanded && (
              <motion.div
                className="absolute bg-white top-full left-0 w-full text-opacity-80 rounded-[8vw] text-[#2B2A29] font-poppins font-medium text-16px-mvw p-25px-mvw mt-[-3.93vw] z-50 "
                initial={{ opacity: 0, height: 0 }} // Start state
                animate={{ opacity: 1, height: "auto" }} // Animate to these values
                exit={{ opacity: 0, height: 0 }} // Exit animation
                transition={{ duration: 0.3, ease: "easeInOut" }} // Duration and easing
              >
                {categories.map(
                  (category, index) =>
                    index !== activeCategoryIndex && (
                      <div key={category.id}>
                        <div
                          className="cursor-pointer font-poppins font-medium"
                          onClick={() => {
                            onCategorySelect(index);
                            setIsExpanded(false);
                          }}
                        >
                          {category.model}
                        </div>
                        {index !== categories.length - 1 && (
                          <div className="w-full mx-auto border-[1px] border-[#2B2A29] opacity-10 my-13px-mvw"></div>
                        )}
                      </div>
                    )
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default CategorySelector;
