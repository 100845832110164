import React from "react";

const VisionCard = ({ data }) => {
  return (
    <>
      {data.map((item) => (
        <div key={item.id} className=" w-full h-full ">
          <div className="border w-full md:min-h-[25.65vw] flex items-start  border-solid border-[#707070] border-opacity-40 md:rounded-[1.5vw] rounded-[5vw] p-24px-mvw md:p-32px-vw">
            <div className="flex flex-col gap-8px-mvw md:gap-y-16px-vw font-poppins text-[#2B2A29] items-start justify-start">
              <div className="max-w-40px-mvw md:max-w-60px-vw">
                <img src={item.img} alt="vision logo" />
              </div>
              <div className="md:text-32px-vw text-24px-mvw font-medium">{item.title}</div>
              <div className="md:text-20px-vw text-16px-mvw font-regular text-[#2B2A29] opacity-80">{item.desc}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default VisionCard;
