import React, { useEffect, useState } from "react";
import Button from "./Button";
import { jsPDF } from "jspdf";
import SectionHeading from "./SectionHeading";
import { AiOutlineClose } from "react-icons/ai";
import Popup from "./Popup";
import config from "../../config/config";
import helper from "../../helper/helper";

const DownloadCard = ({ data, onOpenModal, productData }) => {
  const baseUrl = config.api_url;

  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const handleOpenModal = async (data) => {
    if (checkIfUserSubmitted()) {
      const userInfo = JSON.parse(localStorage.getItem("userFormData") || "{}");
      if (productData && Object.keys(userInfo).length > 0) {
        const leadData = {
          ...userInfo,
          category: productData.category,
          sub_category: productData.subCategory,
          product_name: productData.product,
        };

        await helper.post(`${baseUrl}/product-leads`, leadData);
      }
      downloadPdf(data);
    } else {
      onOpenModal(data);
    }
  };

  const checkIfUserSubmitted = () => {
    const submittedData = localStorage.getItem("userSubmittedForm");

    return submittedData ? JSON.parse(submittedData) : false;
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const downloadPdf = (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = pdfUrl.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {data.map((item) => (
        <div
          key={item.id}
          className="md:w-[27.92vw] w-full relative   overflow-hidden cursor-pointer border-[1px] border-solid hover:border-none border-[#707070] group border-opacity-40 rounded-[5vw] md:rounded-[1.8vw] "
        >
          <div
            className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100  transition-opacity duration-300"
            style={{ backgroundImage: "url('/images/investors/card-bg.png')" }}
          />
          <div className="flex flex-col text-[#2B2A29] font-poppins group-hover:text-white items-start md:p-40px-vw p-26px-mvw md:gap-y-12px-vw ">
            {/* <div className="font-medium md:text-32px-vw text-18px-mvw group-hover:text-white z-10">
              0{item.id}
            </div> */}
            <div className="font-poppins font-medium md:text-32px-vw text-18px-mvw group-hover:text-white z-[4] ">
              {item.title}
            </div>
            <div className="md:mt-40px-vw mt-40px-mvw">
              {/* Button that triggers PDF download */}
              <Button
                title={"Download PDF"}
                className="border-[#707070]"
                customClasses={
                  "group-hover:border-white transition-colors duration-300"
                }
                onClick={() => handleOpenModal(item.data)}
              />
            </div>
          </div>
        </div>
      ))}
      {/* {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )} */}
    </>
  );
};

export default DownloadCard;
