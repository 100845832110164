import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import Aos from "aos";
import { oct } from "../../../Data/RectinaGlaucoma";
import Marquee from "react-marquee-slider";
import BottomSlider from "../../slider/BottomSlider";
import { motion, AnimatePresence } from "framer-motion";

import ImageSlider from "../../slider/ImageSlider";
import CategorySelector from "../../category/CategorySelector";
import Popup from "../../common/Popup";
import { useProductFromSlug } from "../../../hooks/useIndexFromUrl";

const Oct = () => {
  const [activeIndex, setActiveIndex] = useProductFromSlug(oct, 0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [productData, setProductData] = useState(null);

  const handleOpenModal = (pdfUrl, item) => {
    setSelectedPdf(pdfUrl);
    setProductData({
      category: "Retina & Glaucoma",
      subCategory: "OCT",
      product: item.model,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const handleCategorySelect = (index) => {
    setActiveIndex(index);
  };

  const activeCategory = oct[activeIndex];
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | OCT"}
        centerHeading={"OCT"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={oct}
          activeCategoryIndex={activeIndex}
          onCategorySelect={handleCategorySelect}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full ">
            <div className="grid md:grid-cols-2 md:px-[7.29vw] px-[4.27vw] md:px-[0.83vw] gap-x-[6vw] grid-cols-1 gap-y-[10.67vw] md:gap-y-[2.08vw] md:gap-y-0 py-[10.67vw] md:py-[2.08vw] md:py-0 md:pt-[4.43vw] md:pb-[7vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-[4.27vw] md:gap-y-[0.83vw] md:gap-y-[1.56vw]">
                <div className="md:max-w-full md:max-h-[2.60vw]  max-h-[9.33vw] md:max-h-[1.82vw] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain object-left  block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key Features:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-full max-h-full  object-contain"
                  />
                ))}
              </div>
            </div>

            <h1
              className="font-raleway px-16px-mvw md:px-0  text-left md:text-center font-bold pt-30px-mvw md:pt-0 text-24px-mvw md:text-32px-vw text-[#2B2A29]"
              style={{ display: "none" }}
            >
              PRODUCT KEY FEATURES
            </h1>

            <div className="w-full h-full md:px-[7.29vw] px-[4.27vw] md:px-[0.83vw] md:my-[3.13vw] pt-[5.33vw] md:pt-[1.04vw] md:pt-0 pb-[5.33vw] md:pb-[1.04vw] md:pb-0 grid md:grid-cols-3 gap-[5.33vw] md:gap-[1.04vw] relative">
              {/* Left Grid */}
              <div className="grid md:grid-cols-1 md:pr-[3.13vw] gap-[5.33vw] md:gap-[1.04vw]">
                {item.keyFeatureObject.key_feature
                  .slice(
                    0,
                    item.keyFeatureObject.key_feature.length === 7 ? 3 : 4
                  ) // 3 items if 7 objects, otherwise 4
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-[2.08vw] pb-[8.53vw] md:pb-[1.67vw] items-start justify-start"
                    >
                      <div className="flex items-center gap-x-[5.33vw] md:gap-x-[1.04vw] md:gap-x-[1.04vw]">
                        <div className="bg-[#519A7F] w-[12.8vw] md:w-[2.5vw] h-[12.8vw] md:h-[2.5vw] md:w-[3.13vw] md:h-[3.13vw] md:p-[0.63vw] p-[1.6vw] md:p-[0.31vw] rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-[1.25vw] text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw text-16px-mvw opacity-80 md:pt-16px-vw pt-12px-mvw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>

              {/* Center Image */}
              <div
                className={`hidden md:flex ${
                  item.keyFeatureObject.key_feature.length === 7
                    ? "flex-col md:items-center items-start justify-start md:justify-center"
                    : ""
                } gap-y-100px-vw w-full md:h-full relative`}
              >
                {/* <div className=""> */}
                <img
                  src={item.keyFeatureObject.commonImg}
                  alt="center"
                  className="w-full   h-full object-contain"
                />
                {/* </div> */}

                {/* 7th Object under Image */}
                {item.keyFeatureObject.key_feature.length === 7 && (
                  <div
                    key={item.keyFeatureObject.key_feature[6].id}
                    className="w-full flex flex-col items-center justify-center text-center"
                  >
                    <div className="flex items-center gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                        <img
                          src={item.keyFeatureObject.key_feature[6].img}
                          alt="icon"
                          className="w-full h-full object-contain"
                        />
                      </div>

                      <div className="text-24px-vw font-semibold text-[#2B2A29]">
                        {item.keyFeatureObject.key_feature[6].title}
                      </div>
                    </div>

                    <div className="font-poppins md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw md:pt-16px-vw leading-relaxed text-[#2B2A29]">
                      {item.keyFeatureObject.key_feature[6].desc}
                    </div>
                  </div>
                )}
              </div>

              {/* Right Grid */}
              <div className="grid md:grid-cols-1 md:pl-60px-vw gap-20px-vw">
                {item.keyFeatureObject.key_feature
                  .slice(
                    item.keyFeatureObject.key_feature.length === 7 ? 3 : 4, // Start after 3 for 7 objects, otherwise after 4
                    item.keyFeatureObject.key_feature.length === 7 ? 6 : 8 // End at 6 for 7 objects, otherwise at 8
                  )
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-40px-vw pb-32px-mvw items-start justify-start"
                    >
                      <div className="flex items-center gap-x-20px-mvw md:gap-x-20px-vw">
                        <div className="bg-[#519A7F] w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-24px-vw text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw text-16px-mvw opacity-80 md:pt-16px-vw pt-12px-mvw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {
              <div className="w-full  md:px-0 px-16px-mvw  gap-x-16px-vw gap-y-20px-mvw md:gap-y-0 md:pt-[3vw] pt-20px-mvw  flex md:flex-row flex-col items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={(pdfUrl) => handleOpenModal(pdfUrl, item)}
                  productData={{
                    category: "Retina & Glaucoma",
                    subCategory: "OCT",
                    product: item.model,
                  }}
                />
              </div>
            }

            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  {/* <div className="md:mb-100px-vw"> */}
                  <ImageSlider data={item.imggallery} />
                  {/* </div> */}
                  {/* Separator Line */}
                  {/* <div className="absolute left-0 md:w-[87%] md:mb-[12vw] md:mx-[7vw] pb-[4vw] border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                  {/* Video and Image Navigation slider */}
                  {/* <BottomSlider slides={item.videoData} /> */}
                </div>
              ))}
          </div>
        ))}
      </section>
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
          data={productData}
        />
      )}
    </main>
  );
};

export default Oct;
