import Aos from "aos";
import React, { useEffect } from "react";

const CenterHeading = ({ title }) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <h2
      className="grid place-items-center text-center md:mt-[2vw] mt-[12vw]  md:text-[4.43vw] text-[8.53vw] md:text-[1.67vw] text-white font-raleway font-bold"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      {title}
    </h2>
  );
};

export default CenterHeading;
